// This file is deprecated. Use utilsV2.js instead.
import getSymbolFromCurrency from 'currency-symbol-map';
import { isNil, isPlainObject, isArray, camelCase, pick, isEmpty } from 'lodash';
import { message } from 'antd';
import qs from 'qs';

export function iterate(objOrArray, { onItem, onDive, shouldBreak = false, path = [] }) {
  if (onDive) {
    onDive(objOrArray, { path });
  }

  if (isPlainObject(objOrArray)) {
    for (const [key, value] of Object.entries(objOrArray)) {
      onItem({ parentType: 'object', path, key, value });
      if (shouldBreak && shouldBreak({ parentType: 'object', path, key, value })) {
        break;
      }
      iterate(value, { onItem, onDive, shouldBreak, path: [...path, key] });
    }
  }
  if (isArray(objOrArray)) {
    for (const [idx, item] of objOrArray.entries()) {
      onItem({ parentType: 'array', path, item, idx });
      if (shouldBreak && shouldBreak({ parentType: 'array', path, item, idx })) {
        break;
      }
      iterate(item, { onItem, onDive, shouldBreak, path: [...path, idx] });
    }
  }
}

export function classNames(...names) {
  return names.filter((name) => name).join(' ');
}

export function optionalProps(propsObject) {
  const result = {};

  for (const propEntry of Object.entries(propsObject)) {
    const propName = propEntry[0];
    const isPropAllowed = propEntry[1][0];
    const propValue = propEntry[1][1];

    if (isPropAllowed) {
      result[propName] = propValue;
    }
  }

  return result;
}

function createEnum(...keys) {
  const result = {};
  for (const key of keys) {
    result[key] = key;
  }
  return result;
}

export const enums = {
  status: createEnum('INITIAL', 'LOADING', 'SUCCESS', 'FAILURE'),
  userRoles: createEnum('GUEST', 'GUEST_ANONYMOUS', 'HOST'),
  productCategories: {
    GRAB_AND_GO: 'GG',
    LOCAL_PRODUCT: 'LP',
    LOCAL_SERVICE: 'LS',
  },
};

export const mergeContainerProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...Object.fromEntries(
      Object.entries(dispatchProps).map((item) => [
        item[0],
        (argsObj) => item[1](argsObj, { ownProps, stateProps, dispatchProps }),
      ]),
    ),
  };
};
export class EffectError {
  constructor(errors) {
    this.message = 'One or more errors occurred while processing the effect.';
    this.name = 'EffectError';
    this.data = errors;
  }
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getBase64(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });
}

export function getBase64FromPath(path, options = {}) {
  return new Promise((resolve) => {
    fetch(path, options)
      .then(async (response) => {
        const blob = await response.blob();
        getBase64(blob).then(resolve);
      })
      .catch(() => resolve(path));
  });
}

export function getFirstNonNil(...args) {
  for (const arg of args) {
    if (!isNil(arg)) {
      return arg;
    }
  }
}

export function createUploadHandler(callBack) {
  return ({ file }) => {
    switch (file.status) {
      case 'uploading':
        callBack({
          isUpoading: true,
          file: null,
        });
        break;
      case 'done':
        callBack({
          isUpoading: false,
          file: file.originFileObj,
        });
        break;

      default:
        // error or removed
        callBack({
          isUpoading: true,
          file: null,
        });
    }
  };
}

export function createUploadProps({ accepts = ['.jpeg', '.jpg', '.png'], maxSize = 5 } = {}) {
  return {
    accept: accepts.join(','),
    beforeUpload: (file) => {
      const isValidType = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isValidType) {
        message.error('You can only upload JPG/PNG file!');
      }
      const isValidSize = file.size / 1024 / 1024 < maxSize;
      if (!isValidSize) {
        message.error(`Image must smaller than ${5}MB!`);
      }
      return isValidType && isValidSize;
    },
    customRequest: ({ onSuccess }) => {
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
    },
  };
}

export function createErrorsObject() {
  return {
    fields: {},
    nonFields: [],
  };
}

export function currencyToSymbol(currency) {
  return getSymbolFromCurrency(currency);
}

function createCurrencyFormatter(currency) {
  const basicFields = {
    minimumFractionDigits: 2,
  };

  if (currency) {
    return new Intl.NumberFormat('en-US', {
      ...basicFields,
      style: 'currency',
      currency,
    });
  } else {
    return new Intl.NumberFormat('en-US', {
      ...basicFields,
    });
  }
}

const numberFormatter = new Intl.NumberFormat('en-US', {});

const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export function formatCurrency(amount, currency) {
  if (amount !== 0 && (!amount || (typeof amount === 'string' && amount.trim() === ''))) {
    return null;
  }

  const value = createCurrencyFormatter(currency).format(
    typeof amount === 'string' ? parseFloat(amount.replace(/,/g, '')) : amount,
  );

  return value;
}

export function formatNumber(num) {
  return num ? numberFormatter.format(num) : null;
}

export function formatPercent(num) {
  return num ? percentFormatter.format(num) : null;
}

export function calculateYouMake({
  isLocal = false,
  ownerLocalProductAmount = '',
  price,
  commissionLow,
  commissionHigh,
  commissionCutoff,
}) {
  if (isLocal) {
    return ownerLocalProductAmount;
  }
  const commission = price > commissionCutoff ? commissionLow : commissionHigh;
  return price - price * commission;
}

export function base64SToFile(base64String, fileType) {
  const byteString = window.atob(base64String);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: fileType });

  return blob;
}

export function base64SToURL(base64String, fileType) {
  const blob = base64SToFile(base64String, fileType);
  const url = URL.createObjectURL(blob);

  return url;
}

export function saveBase64File({ base64String, fileType, fileName }) {
  const url = base64SToURL(base64String, fileType);

  const link = document.getElementById('_download-dummy');
  link.download = fileName;
  link.href = url;
  link.click();
}

export function saveBase64Png({ base64String, fileName }) {
  return saveBase64File({ base64String, fileName, fileType: 'image/png' });
}

export function saveBase64Svg({ base64String, fileName }) {
  return saveBase64File({ base64String, fileName, fileType: 'image/svg+xml' });
}

export function getQuerystringData(querystring) {
  const result = {};

  const parsed = qs.parse(querystring, { ignoreQueryPrefix: true });
  for (const key of Object.keys(parsed)) {
    const newKey = camelCase(key);

    result[newKey] = parsed[key].endsWith('/') ? parsed[key].slice(0, -1) : parsed[key];
  }

  return result;
}

export function getHostname() {
  return window.location.host;
}

export function getBaseUrl() {
  return window.location.origin;
}

export function openInNewTab(link) {
  const a = document.createElement('a');
  a.href = link;
  a.target = '_blank';
  a.click();
  document.body.removeChild(a);
}

export function cleanObject(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== undefined));
}

export function sortByString(a, b) {
  return a.localeCompare(b, 'en', { sensitivity: 'base' });
}

export const getDigitsFromValue = (value = '') => `${value}`.replace(/[^0-9|.-]/g, '') || '';

export const getNumberFromValue = (value = '') => `${value}`.replace(/[^0-9]/g, '') || '';

export const isMobile = (function (a) {
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      a,
    ) ||
    // eslint-disable-next-line no-useless-escape
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      a.substr(0, 4),
    )
  ) {
    return true;
  }
  return false;
})(navigator.userAgent || navigator.vendor || window.opera);

const detectBrowser = () => {
  const { userAgent } = navigator;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    return 'chrome';
  }
  if (userAgent.match(/firefox|fxios/i)) {
    return 'safari';
  }

  if (userAgent.match(/safari/i)) {
    return 'safari';
  }

  if (userAgent.match(/opr\//i)) {
    return 'opera';
  }

  if (userAgent.match(/edg/i)) {
    return 'edge';
  }

  return 'Unkonwn';
};

export const isSafari = detectBrowser() === 'safari';

export const emailRegex = /(.+)@(.+){2,}\.(.+){2,}/;

export const objToDotNotaion = (obj, prevKey = '') => {
  const keys = [];

  function getPrevValue(key) {
    return prevKey ? `${prevKey}.${key}` : key;
  }

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'object') {
      const innerKeys = objToDotNotaion(value, key);
      keys.push(...innerKeys.map(getPrevValue));
      continue;
    }

    keys.push(getPrevValue(key));
  }

  return keys;
};

export const getUpdateValues = (values, updatedValues) => {
  const keys = objToDotNotaion(updatedValues);
  if (!isEmpty(updatedValues)) {
    return pick(values, keys);
  }

  return values;
};

export const downloadRedableStream = (response, fileName) => {
  return response
    .blob()
    .then((blob) => URL.createObjectURL(blob))
    .then((href) => {
      const link = document.getElementById('_download-dummy');
      link.download = fileName;
      link.href = href;
      link.click();
    });
};

export const proxyParams = (params) => {
  let lastParams = params;
  return (newParam, callback) => {
    const [key, value] = Object.entries(newParam).at(0) ?? [];
    const newParams = Object.fromEntries(
      Object.entries({ ...lastParams, [key]: encodeURIComponent(value) }).filter(([_, value]) =>
        Boolean(value),
      ),
    );
    lastParams = newParams;
    const search = new URLSearchParams(newParams).toString();
    callback(search);
  };
};
