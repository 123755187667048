import { OrderStatus, OrderStatusMap } from 'api/types/order';

export const STATUSES_OPTIONS = [
  {
    label: OrderStatusMap[OrderStatus.COMPLETED],
    value: OrderStatus.COMPLETED,
  },
  {
    label: OrderStatusMap[OrderStatus.HOLD],
    value: OrderStatus.HOLD,
  },
  {
    label: OrderStatusMap[OrderStatus.CANCEL],
    value: OrderStatus.CANCEL,
  },
  {
    label: OrderStatusMap[OrderStatus.REFUNDED],
    value: OrderStatus.REFUNDED,
  },
];
