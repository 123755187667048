import React from 'react';
import Button, { ButtonProps } from 'ui-kit/Button';
import icons from './icons';

interface Props extends Omit<ButtonProps, 'children'> {
  social: 'google' | 'facebook';
  label?: React.ReactNode;
}

const SocialButton: React.FC<Props> = ({ social, label, ...rest }) => {
  return (
    <Button
      variant="outlined"
      startIcon={<img width={20} height={20} src={icons[social]} alt={`${social}-icon`} />}
      {...rest}
    >
      {label}
    </Button>
  );
};

export default SocialButton;
