/* eslint-disable n/handle-callback-err */
import React, { useCallback, useRef } from 'react';
import clsx from 'clsx';
import './InputNew.scss';
import InputAdornment from './InputAdornment';

interface ExtraInputProps {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

type BaseInputProps =
  | React.InputHTMLAttributes<HTMLInputElement>
  | React.TextareaHTMLAttributes<HTMLAreaElement>;

export interface InputProps extends Pick<BaseInputProps, 'onChange' | 'onBlur' | 'name' | 'value'> {
  id?: string;
  type?: string;
  error?: boolean;
  className?: string;
  disabled?: boolean;
  rows?: number;
  showCount?: boolean;
  multiline?: boolean;
  placeholder?: string;
  inputProps?: BaseInputProps;
  InputProps?: ExtraInputProps;
  inputRef?: React.RefCallback<HTMLInputElement> | React.MutableRefObject<HTMLInputElement>;
}

const BASE_CLASS = 'ui-kit-input';

const InputNew = React.forwardRef(
  (
    {
      error,
      InputProps,
      inputProps,
      multiline = false,
      disabled = false,
      rows = 1,
      placeholder,
      inputRef,
      className,
      showCount = false,
      ...rest
    }: InputProps,
    _ref,
  ) => {
    const { startAdornment, endAdornment } = InputProps ?? {};
    const andornment = startAdornment || endAdornment;
    const defaultInputRef = useRef<HTMLInputElement>(null);

    const parsedPropsByType = useCallback((props) => {
      if (props.type === 'number') {
        return {
          ...props,
          value: props.value ?? '',
          onChange: (e) => {
            const value = e.target.value ? parseInt(e.target.value, 10) : null;
            e.target.value = value;
            props.onChange(value);
          },
        };
      }

      return props;
    }, []);

    const Component = useCallback((props) => {
      const parsedProps = multiline
        ? { ...props, multiline: multiline.toString(), rows }
        : parsedPropsByType(props);
      return React.createElement(multiline ? 'textarea' : 'input', {
        ...parsedProps,
        ref: (e) => {
          if (typeof inputRef === 'function') {
            inputRef(e);
          } else if (inputRef) {
            inputRef.current = e;
          }
          defaultInputRef.current = e;
        },
      });
    }, []);
    return (
      <div
        className={clsx(
          BASE_CLASS,
          `${BASE_CLASS}-${error ? 'error' : 'active'}`,
          {
            [`${BASE_CLASS}-disabled`]: disabled,
            [`${BASE_CLASS}__startAdornment`]: Boolean(startAdornment),
            [`${BASE_CLASS}__endAdornment`]: Boolean(endAdornment),
          },
          className,
        )}
        data-count={
          !showCount
            ? ''
            : `${defaultInputRef.current?.value.length || `${rest.value ?? ''}`.length || 0} / ${
                inputProps?.maxLength
              }`
        }
      >
        {andornment && <InputAdornment>{andornment}</InputAdornment>}
        <Component
          className={clsx(`${BASE_CLASS}-field`, {
            [`${BASE_CLASS}-field__startAdornment`]: Boolean(startAdornment),
            [`${BASE_CLASS}-field__endAdornment`]: Boolean(endAdornment),
          })}
          disabled={disabled}
          placeholder={placeholder}
          {...rest}
          {...inputProps}
        />
        <fieldset />
      </div>
    );
  },
);

export default InputNew;
