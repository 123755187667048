import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import './LoadingModal.scss';

const LoadingModal = ({ title, topCopy, bottomCopy, isVisible }) => {
  return (
    <Modal className="LoadingModal" visible={isVisible} footer={null} closable={false}>
      <div className="LoadingModal_content">
        <h1 className="LoadingModal_title">{title}</h1>
        <p className="LoadingModal_copy">{topCopy}</p>
        <div className="LoadingModal_loading">
          <span className="LoadingModal_spinner" />
        </div>
        <p className="LoadingModal_copy">{bottomCopy}</p>
      </div>
    </Modal>
  );
};

LoadingModal.propTypes = {
  title: PropTypes.string,
  topCopy: PropTypes.string,
  bottomCopy: PropTypes.string,
  isVisible: PropTypes.bool,
};

export default LoadingModal;
