import React, { useEffect } from 'react';
import { message } from 'antd';
import ErrorMessage from 'components/ErrorMessage';
import { SOCIAL_ERROR_CODES } from 'consts';
import { useRouteMatch, useHistory } from 'react-router-dom';
import useQuery from './useQuery';

export default function useSocialAuth() {
  const match = useRouteMatch();
  const history = useHistory();

  const { error, message: defaultMessage } = useQuery();

  function showSocialAuthMessage() {
    message.error(
      <ErrorMessage
        message="There is already an account associated with that email address."
        {...(match.path !== '/login/'
          ? { action: () => history.push('/login'), actionLabel: 'Click here to log in now' }
          : {})}
      />,
      3,
    );
  }

  useEffect(() => {
    if (error === SOCIAL_ERROR_CODES.alreadyAssociated) {
      showSocialAuthMessage();
    } else if (error) {
      message.error(defaultMessage);
    }
  }, []);

  return { error, showSocialAuthMessage };
}
