import React from 'react';
import clsx from 'clsx';
import checkmarkSvg from './checkmark.svg';
import './InputRules.scss';

interface Rule {
  message: string;
  isValid: boolean | (() => boolean);
}

interface Props {
  label: string;
  rules: Rule[];
}

export default function InputRules({ label, rules }: Props) {
  return (
    <div className="ui-kit-input-rules">
      <div className="ui-kit-input-rules-header">{label}</div>
      <div className="ui-kit-input-rules-body">
        {rules.map((rule, index) => {
          const isValid = typeof rule.isValid === 'function' ? rule.isValid() : rule.isValid;
          return (
            <div
              key={index}
              className={clsx({
                'ui-kit-input-rule': !isValid,
                'ui-kit-input-rule-valid': isValid,
              })}
            >
              <img className="ui-kit-input-rule-icon" width={14} height={11} src={checkmarkSvg} />
              <div className="ui-kit-input-rule-message">{rule.message}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
