export const getReferrer = () => {
  if (window.RH) {
    return window.RH.referrer;
  }
  return null;
};

export const getCampaign = () => {
  if (window.RH) {
    return window.RH.campaign;
  }
  return null;
};
