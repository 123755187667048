import { Product, ProductCategory, ProductSubcategory, InputProduct } from 'api/types/product';
import { ControllerFieldState } from 'react-hook-form';
import * as yup from 'yup';
import _pick from 'lodash/pick';
import _camelCase from 'lodash/camelCase';
export interface InitialValues {
  subcategory: ProductSubcategory | null | string;
  subcategoryName?: string;
  product: Partial<Product> | null;
  notifyThreshold?: boolean;
  notifyDelegates?: boolean;
  includeExtraFields?: boolean;
}

interface InitalSettingsValues {
  product: {
    category?: ProductCategory;
    quantity?: number;
    preorderBy?: number;
    hasQuantity?: boolean;
    requiresApproval?: boolean;
  };
  notifyThreshold?: boolean;
  notifyDelegates?: boolean;
  includeExtraFields?: boolean;
}

export const getFieldError = (fieldState: ControllerFieldState) => {
  const isTouched = fieldState.error?.type === 'custom' || fieldState.isTouched;
  return {
    error: Boolean(isTouched && fieldState.error),
    errorMessage: isTouched && fieldState.error?.message,
  };
};

export const getInititalValuesFromProduct = (product: Product): InitialValues => {
  const { subcategory } = product ?? {};
  const subcategories = Object.values(ProductSubcategory) as string[];
  const parseSubcategory = subcategories.includes(subcategory)
    ? subcategory
    : ProductSubcategory.SomethingElse;
  return {
    subcategory: parseSubcategory,
    product: {
      ..._pick(product, [
        'id',
        'name',
        'price',
        'image',
        'category',
        'description',
        'instructions',
        'quantity',
        'preorderBy',
        'alertThreshold',
        'productDelegates',
        'hasQuantity',
        'requiresApproval',
        'extraFields',
        'externalLink',
        'isGuestPrice',
      ]),
      subcategory: parseSubcategory,
    },
    notifyThreshold: product?.alertThreshold > 0,
    notifyDelegates: product?.productDelegates?.length > 0,
    includeExtraFields: product?.extraFields?.length > 0,
    subcategoryName: parseSubcategory === ProductSubcategory.SomethingElse ? subcategory : '',
  };
};

export const getInitalSettingsBySubcategory = (
  subcategory: ProductSubcategory | string,
): InitalSettingsValues => {
  switch (subcategory) {
    case ProductSubcategory.GoodsAndEssentials:
      return {
        product: { category: ProductCategory.IH, quantity: 1, hasQuantity: true },
        notifyThreshold: true,
      };
    case ProductSubcategory.GuestServices:
      return {
        product: { category: ProductCategory.PO, preorderBy: 7, hasQuantity: false },
        notifyThreshold: false,
        notifyDelegates: true,
      };
    case ProductSubcategory.AddOns:
      return {
        product: { category: ProductCategory.PO, preorderBy: 7, hasQuantity: false },
        notifyDelegates: true,
      };
    default:
      return { product: { quantity: 1, hasQuantity: true } };
  }
};

const validSubCategory = yup
  .mixed()
  .oneOf(Object.values(ProductSubcategory))
  .required('Required value');

const validCategory = yup.mixed().oneOf(Object.values(ProductCategory)).required('Required value');

const validProduct = yup.object().shape({ subcategory: validSubCategory }).required();

export const validSubcategorySchema = yup.object({
  subcategory: validSubCategory,
});

export const validPartialProductSchema = yup.object().shape({ product: validProduct });

export const validProductSchema = yup.object().shape({
  product: validProduct.shape({
    name: yup.string().required('Required value'),
    category: yup.string().nullable(),
    price: yup
      .string()
      .when('category', {
        is: (category) => category !== ProductCategory.LINK,
        then: yup.string().nullable().required('Required value'),
      })
      .when('isGuestPrice', {
        is: true,
        then: yup.string(),
      }),
    image: yup.string().required('Required value'),
    description: yup.string().when('category', {
      is: (category) => category !== ProductCategory.LINK,
      then: yup.string().required('Required value'),
    }),
    subcategory: validSubCategory,
  }),
});

const validNumber = (minimum: number = 1) =>
  yup
    .number()
    .typeError('Field must be a number')
    .nullable()
    .min(minimum, `Quantity must be greater than or equal to ${minimum}`)
    .required('Required field');

export const validProductSettingsSchema = yup.object().shape({
  product: yup
    .object()
    .when(['notifyDelegates', 'notifyThreshold', 'includeExtraFields'], (...values) => {
      const [notifyDelegates, notifyThreshold, includeExtraFields] = values;

      let schema = yup.object().shape({
        category: validCategory,
        preorderBy: yup
          .number()
          .nullable()
          .when('category', {
            is: (category) => category === ProductCategory.PO,
            then: validNumber(0),
          }),
        quantity: yup.number().when('hasQuantity', {
          is: true,
          then: validNumber(1),
        }),
        requiresApproval: yup.boolean().when('category', {
          is: (category) => category === ProductCategory.PO,
          then: yup.boolean().required('Required field'),
        }),
        externalLink: yup
          .string()
          .nullable()
          .when('category', {
            is: (category) => category === ProductCategory.LINK,
            then: yup.string().url().required('Required field'),
          }),
      });

      if (notifyThreshold) {
        schema = schema.shape({
          alertThreshold: validNumber(1),
        });
      }

      if (notifyDelegates) {
        schema = schema.shape({
          productDelegates: yup.array().min(1).required('Required field'),
        });
      }

      if (includeExtraFields) {
        schema = schema.shape({
          extraFields: yup
            .array()
            .of(
              yup.object().shape({
                label: yup.string().required('Required field').min(1),
              }),
            )
            .min(1)
            .required('Required field'),
        });
      }

      return schema;
    }),
});

export const parseValuesForRequest = (
  product: Partial<Product>,
  subcategoryName: string,
  dirtyFields?: string[],
): InputProduct => {
  const parseProduct = structuredClone(product);

  if (product.subcategory === ProductSubcategory.SomethingElse) {
    parseProduct.subcategory = subcategoryName;
  }

  if (dirtyFields?.length) {
    return _pick(parseProduct, [...dirtyFields, 'id']);
  }

  return parseProduct;
};
