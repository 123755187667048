import React from 'react';
import { useLocation } from 'react-router-dom';

export default function useQuery<T extends {}>(): T {
  const { search } = useLocation();
  const queryParams = React.useMemo(() => {
    const params = new URLSearchParams(search);
    return Array.from(params).reduce(
      (res, [key, value]) => ({ ...res, [key]: decodeURIComponent(value) }),
      {},
    );
  }, [search]);

  return queryParams as T;
}
