import { ECoHostStatus } from 'components/CoHosts/constants';
import { BASE_URL, post, useFetch } from 'utils/sdk';

export interface ICoHost {
  id: number;
  email: string;
  status: ECoHostStatus;
  phone_number: string;
  is_request_sms_enabled: boolean;
  is_sales_sms_enabled: boolean;
  is_marketing_sms_enabled: boolean;
}

export const useCoHostList = () => useFetch<Array<ICoHost>>(`services/co-host/list/`);

export const coHostCreate = ({ coHost }: { coHost: ICoHost }) =>
  post<Promise<ICoHost>>(`${BASE_URL}services/co-host/create/`, coHost);

export const coHostUpdate = ({
  id,
  phone_number,
  is_request_sms_enabled,
  is_sales_sms_enabled,
  is_marketing_sms_enabled,
}: {
  id: number;
  phone_number: string;
  is_request_sms_enabled: boolean;
  is_sales_sms_enabled: boolean;
  is_marketing_sms_enabled: boolean;
}) =>
  post<Promise<ICoHost>>(`${BASE_URL}services/co-host/${id}/update/`, {
    phone_number,
    is_request_sms_enabled,
    is_sales_sms_enabled,
    is_marketing_sms_enabled,
  });

export const coHostRemove = ({ id }: { id: number }) =>
  post<Promise<void>>(`${BASE_URL}services/co-host/${id}/remove/`);

export const useCoHostCheckUUID = ({ uuid }: { uuid: string }) =>
  useFetch<{ is_valid: boolean }>(`services/co-host/${uuid}/check-uuid/`);

export const coHostSetupPassword = ({ uuid, password }: { uuid: string; password: string }) =>
  post<Promise<void>>(`${BASE_URL}services/co-host/${uuid}/setup-password/`, { password });

export const coHostResendInvitation = ({ id }: { id: number }) =>
  post<Promise<void>>(`${BASE_URL}services/co-host/${id}/resend-invitation/`);
