import { post, get, getF2BObject } from 'api/api';
import useSWR, { useSWRConfig } from 'swr';
import { getCampaign, getReferrer } from 'utils/referralHero';
import { API } from '../consts';
import { GuestRegistration, Role, UserSnippet } from './types/auth';
import { OwnerDetail } from './types/owner';

const roleToApiPath = {
  login: {
    [Role.GUEST_ANONYMOUS]: '/services/guest/login/',
    [Role.HOST]: '/services/owner/login/',
  },
};

const getRandomPassword = () =>
  '1aA' +
  Array.from(new Array(16))
    .map(() => String.fromCharCode(Math.random() * 86 + 40))
    .join('');

export const login = (role, email, password) => {
  const isAnonymous = role === Role.GUEST_ANONYMOUS;

  return post<OwnerDetail>(roleToApiPath.login[role], {
    email,
    password,
    isAnonymous,
  });
};

export const logoutHost = () => get<OwnerDetail>('/services/owner/logout/');

export const loginHost = (email, password) => login(Role.HOST, email, password);

export const loginAnonymousGuest = (email, password) =>
  login(Role.GUEST_ANONYMOUS, email, password);

export const createAnonymousSession = () => {
  const password = getRandomPassword();
  return post<GuestRegistration>('/services/guest/', {
    isAnonymous: true,
    isActive: true,
    password,
  }).then(({ data: { email } }) => loginAnonymousGuest(email, password));
};

export const loginMasquerade = () => {
  const url = '/services/admin/masquerade/login/';
  return post<OwnerDetail>(url, {});
};

export const getCurrentOwner = () => {
  const url = '/services/owner/current/';
  return get<OwnerDetail>(url, {});
};

export const getCurrentGuest = () => {
  const url = '/services/guest/current/';
  return get<OwnerDetail>(url, {});
};

export async function checkIfUserExists(payload) {
  const { email } = payload;

  const result = await post('/services/guest/exists/', {
    email,
  });

  if (result.ok) {
    return result.data;
  } else {
    // throw new Error(result.errors);
  }
}

export const login2 = (role, email, password) => {
  const userData = login(role, email, password);
  // mutate('/services/user/current/', userData, false)
  return userData;
};

const getCurrentUser = () => {
  const url = '/services/user/current/';
  return get<UserSnippet>(url, {});
};

export const useCurrentUser = () => {
  const { mutate } = useSWRConfig();
  const key = '/services/user/current/';
  const { data, error } = useSWR(key, getCurrentUser);

  return {
    data: data?.data,
    invalidate: () => {
      mutate(key);
    },
    isLoading: !error && !data,
    isError: error,
  };
};

export const createOwner = ({ firstName, email, password, promoCode }) => {
  const referrer = getReferrer();
  const campaign = getCampaign();
  const payload = { firstName, email, password, promoCode, isActive: true } as any;
  if (referrer && campaign) {
    payload.referrer = referrer;
    payload.campaign = campaign;
  }
  return post('/services/owner/', payload).then((result) => {
    if (result.ok) {
      return loginHost(email, password);
    } else {
      return new Promise((resolve, reject) => {
        reject(result.errors);
      });
    }
  });
};

export const socialLoginOrCreateOwner = (platform, params = {}) => {
  const referrer = getReferrer();
  const campaign = getCampaign();
  const urlParams = params as any;
  if (referrer && campaign) {
    urlParams.referrer = referrer;
    urlParams.campaign = campaign;
  }
  const parsedParams = getF2BObject(urlParams);
  const queryParams = new URLSearchParams(parsedParams).toString();
  const url = `${API.root}auth/login/${platform}/${queryParams ? `?${queryParams}` : ''}`;
  window.location.href = url;
};

export const disconnectSocialProvider = (payload = {}) => {
  const url = '/auth/disconnect-backend/';
  return post(url, payload);
};
