import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Svg from 'react-inlinesvg';
import { handShakeIcon, contactIcon } from '../icons';
import MenuItems from '../MenuItems';
import { Home, HelpCircle, Eye, Activity } from 'react-feather';
import { URLS } from 'components/Routes/urls';
import { UsergroupAddOutlined } from '@ant-design/icons';

export default function TabOverview({ mode, reset, initialActiveTab, onAddStore }) {
  const history = useHistory();

  return (
    <MenuItems
      mode={mode}
      items={OVERVIEW_MENU_ITEMS(history, onAddStore)}
      reset={reset}
      initialActiveTab={initialActiveTab}
    />
  );
}

TabOverview.propTypes = {
  reset: PropTypes.bool,
  onAddStore: PropTypes.func,
  initialActiveTab: PropTypes.string,
  mode: PropTypes.oneOf(['inline', 'horizontal']),
};

const OVERVIEW_MENU_ITEMS = (history, onAddStore) => [
  {
    key: 'stores-submenu',
    label: 'Stores',
    type: 'submenu',
    icon: <Home />,
    items: [
      {
        key: 'stores',
        label: 'View all stores',
        onClick: () => {
          localStorage.setItem('localSearchInput', '');
          history.push('/overview/stores');
        },
      },
      {
        key: 'add-store',
        label: 'Add store',
        onClick: onAddStore,
      },
    ],
  },
  {
    key: 'partners',
    label: 'Partners',
    icon: <Svg src={handShakeIcon} />,
    onClick: () => history.push('/overview/partners'),
  },
  {
    key: 'divider-1',
    type: 'divider',
  },
  {
    key: 'support-submenu-overview',
    label: 'Support',
    type: 'submenu',
    icon: <HelpCircle />,
    items: [
      {
        key: 'faq',
        label: 'Help center',
        icon: <HelpCircle />,
        onClick: () => {
          window.open('https://thehostcohelp.zendesk.com/hc/en-us', '_blank');
        },
      },
      {
        key: 'explore-store',
        label: 'Example store',
        icon: <Eye />,
        onClick: () => {
          window.open('https://app.thehost.co/store/19/', '_blank');
        },
      },
      {
        key: 'contact-us',
        label: 'Contact us',
        icon: <Svg src={contactIcon} />,
        onClick: () => {
          window.open('https://www.thehost.co/contact', '_blank');
        },
      },
      {
        key: 'request-vendor',
        label: 'Request a vendor',
        icon: <UsergroupAddOutlined />,
        onClick: () => {
          window.open('https://www.thehost.co/vendor-request-form', '_blank');
        },
      },
    ],
  },
  {
    key: 'orders',
    label: 'Orders',
    icon: <Activity />,
    onClick: () => history.push(URLS.ORDERS),
  },
];
