import React from 'react';
import clsx from 'clsx';
import './Badge.scss';

type Variant = 'dot' | 'standard';
type Color = 'primary' | 'secondary' | 'error' | 'success' | 'warning ';

interface Props {
  children: React.ReactNode;
  badgeContent?: React.ReactNode;
  variant?: Variant;
  color?: Color;
  invisible?: boolean;
  classNames?: string;
}

const BASE_CLASS = 'ui-kit-badge';

const Badge: React.FC<Props> = ({
  children,
  color = 'primary',
  variant = 'standard',
  badgeContent,
  invisible = false,
  classNames,
}) => {
  const classNameBadge = clsx(
    `${BASE_CLASS}-${color}`,
    `${BASE_CLASS}-${variant}`,
    invisible && `${BASE_CLASS}-invisible`,
    classNames,
  );
  return (
    <span className={BASE_CLASS}>
      {children}
      <span className={classNameBadge}>{badgeContent}</span>
    </span>
  );
};

export default Badge;
