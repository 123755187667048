import React from 'react';
import PropTypes from 'prop-types';
import { Alert as AntdAlert } from 'antd';
import './Alert.scss';

const Alert = (props) => (
  <div className={`alert alert_type_${props.type}`}>
    <AntdAlert message={props.children} {...props} />
  </div>
);

Alert.propTypes = {
  type: PropTypes.string.required,
  children: PropTypes.node,
  className: PropTypes.string,
  showIcon: PropTypes.bool,
  icon: PropTypes.node,
};

Alert.defaultProps = {
  showIcon: false,
};

export default Alert;
