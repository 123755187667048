import React from 'react';
import { Row, Col } from 'antd';
import { Button, TextField } from 'ui-kit';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { forgotPasswordValidationSchema } from './utils';
import { getFieldError } from 'components/NewProductStepper/utils';

interface InitialValues {
  email: string;
}

interface Props {
  initialValues: InitialValues;
  onSubmit: (values: InitialValues) => Promise<void>;
}

export default function ForgotPasswordForm({ initialValues, onSubmit }: Props) {
  const { control, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(forgotPasswordValidationSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={[0, 24]}>
        <Col xs={24}>
          <Controller
            control={control}
            name="email"
            render={({ field: { ref, ...rest }, fieldState }) => (
              <TextField
                label="Email"
                required
                fullWidth
                inputRef={ref}
                {...getFieldError(fieldState)}
                {...rest}
              />
            )}
          />
        </Col>
        <Col xs={24}>
          <Button
            variant="contained"
            fullWidth
            disabled={!formState.isValid}
            loading={formState.isSubmitting}
            type="submit"
          >
            Send reset link
          </Button>
        </Col>
      </Row>
    </form>
  );
}
