import { ProxyResponse } from 'api';
import { Role } from 'api/types/auth';
import { OwnerDetail } from 'api/types/owner';
import { AuthContextUser } from 'contexts/auth/auth';
import Cookies from 'js-cookie';
import { cookies } from '../consts';
const jwt = require('jsonwebtoken');

const b2fRolesDict = {
  GU: 'GUEST_ANONYMOUS',
  OW: 'HOST',
  AD: 'ADMIN',
};

const getAuthFromCookie = (cookie) => {
  const payload = jwt.decode(cookie);
  const { role, id } = payload;
  return { role: b2fRolesDict[role], id };
};

export const getCookieAuth = () => {
  // check cookies in order of owner -> guest -> admin
  const authCookies = [
    Cookies.get(cookies.owner),
    Cookies.get(cookies.guest),
    Cookies.get(cookies.admin),
  ];

  for (const cookie of authCookies) {
    if (cookie !== undefined) {
      return getAuthFromCookie(cookie);
    }
  }

  // if no cookies at all, just return no auth
  return { role: Role.NO_AUTH, id: null };
};

export const getPartnerAuth = (partnerString) => Cookies.get(cookies[partnerString]);

const parseAuthResultToAuthContext = (
  role: Role,
  result: ProxyResponse<OwnerDetail>,
): AuthContextUser => {
  const defaultValues = { id: result?.data?.id, role };

  return {
    dateCreated: result?.data?.dateCreated,
    name: result?.data?.actor?.firstName,
    email: result?.data?.user?.email,
    stores: result?.data?.stores?.length,
    hasSetPassword: result?.data.user?.hasSetPassword,
    ...defaultValues,
  };
};

export const executeAuthMethod = async (
  options: { role: Role; isMasquerading?: boolean },
  method: (params?: any) => Promise<ProxyResponse<OwnerDetail>>,
): Promise<AuthContextUser> => {
  try {
    const { role, isMasquerading = false } = options;
    const result = await method();
    if (result.ok) {
      const user = parseAuthResultToAuthContext(role, result);
      return { ...user, isMasquerading };
    }
    throw new Error(result.errors.nonFields?.[0]);
  } catch (error) {
    throw new Error(error);
  }
};
