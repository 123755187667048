import * as Yup from 'yup';

const validPassword = Yup.string()
  .min(8, 'min-length')
  .matches(/[A-Z]/, 'uppercase')
  .matches(/\d/g, 'one-digit')
  .matches(/\p{P}/gu, 'special-character');

const signUpValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('This field is required'),
  email: Yup.string().email('Enter a valid email').required('This field is required'),
  password: validPassword,
});

export const signupResolver = async (data) => {
  try {
    const values = await signUpValidationSchema.validate(data, {
      abortEarly: false,
    });

    return {
      values,
      errors: {},
    };
  } catch (errors) {
    return {
      values: {},
      errors: errors.inner.reduce((allErrors, currentError) => {
        if (currentError.path === 'password') {
          const lastErrors = allErrors[currentError.path] ?? [];
          return {
            ...allErrors,
            [currentError.path]: [
              ...lastErrors,
              {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            ],
          };
        }
        return {
          ...allErrors,
          [currentError.path]: {
            type: currentError.type ?? 'validation',
            message: currentError.message,
          },
        };
      }, {}),
    };
  }
};
