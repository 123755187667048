import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import Logo from 'components/Brand/Logo';
import './Header.scss';

const Header = () => {
  return (
    <Layout.Header className="Header">
      <div className="Header_logo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
    </Layout.Header>
  );
};

export default Header;
