import React from 'react';
import { Menu as AntMenu, MenuItemProps, MenuProps } from 'antd';
import './Menu.scss';

const BASE_CLASS = 'ui-kit-menu';

export default function Menu({ children, ...rest }: MenuProps) {
  return (
    <AntMenu className={BASE_CLASS} {...rest}>
      {children}
    </AntMenu>
  );
}

export function MenuItem({ children, ...rest }: MenuItemProps) {
  return (
    <AntMenu.Item className={`${BASE_CLASS}-item`} {...rest}>
      {children}
    </AntMenu.Item>
  );
}

Menu.Item = MenuItem;
