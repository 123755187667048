import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import Svg from 'react-inlinesvg';
import { signageIcon, storeIcon, contactIcon, rocketIcon } from '../icons';
import { useHistory, useLocation } from 'react-router-dom';
import { Settings, Activity, HelpCircle, Eye } from 'react-feather';
import MenuItems from '../MenuItems';
import DeactivateStoreLink from 'pages/settings/DeactivateStoreLink';
import EditStore from 'components/Dashboard/Store/StoreModal/Actions/EditStore';
import { mutate } from 'swr';
import { getStoreDetailsURL } from 'api/store';
import { useAuth } from 'contexts/auth/auth';
import DuplicateStore from 'components/Dashboard/Store/StoreModal/Actions/DuplicateStore';
import { useOwnerDetails } from 'api/owners';
import { Spinner } from 'ui-kit';
import { PaymentPlan } from 'api/types/owner';

export default function TabStore({ mode, store, reset, initialActiveTab }) {
  const { id: ownerId } = useAuth();
  const [openModalKey, setOpenModalKey] = useState('');
  const BASE_PATH = `/dashboard/store/${store?.id}`;
  const history = useHistory();
  const location = useLocation();
  const { data, isLoading } = useOwnerDetails(ownerId);
  const { plan } = data || {};
  const handleOpenModalKey = (key) => {
    setOpenModalKey(key);
  };

  const handleCloseModalKey = () => {
    setOpenModalKey('');
  };

  const handleStoreArchive = () => {
    history.push('/overview/stores/');
    mutate(`/services/owner/${ownerId}/stores/`);
  };

  const onUpdateStore = (store) => {
    if (location.pathname === '/overview/stores/') {
      mutate(`/services/owner/${ownerId}/stores/`);
      mutate(`/services/owner/${ownerId}/detail/`);
    } else {
      mutate(getStoreDetailsURL(store.id));
    }
    localStorage.setItem('defaultStore', JSON.stringify(store));
    handleCloseModalKey();
  };
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Row>
      <Col xs={24}>
        <MenuItems
          mode={mode}
          items={STORE_MENU_ITEMS(
            store,
            BASE_PATH,
            history,
            handleOpenModalKey,
            handleStoreArchive,
            plan,
          )}
          reset={reset}
          initialActiveTab={initialActiveTab}
        />
      </Col>
      {store && (
        <>
          <DuplicateStore
            isOpen={openModalKey === MODAL_KEYS.duplicate}
            store={store}
            onCancel={handleCloseModalKey}
            onSuccess={(store) => history.push(`/dashboard/store/${store.id}/`)}
          />
          <EditStore
            isOpen={openModalKey === MODAL_KEYS.edit}
            store={store}
            onCancel={handleCloseModalKey}
            onSuccess={onUpdateStore}
          />
        </>
      )}
    </Row>
  );
}

const MODAL_KEYS = {
  duplicate: 0,
  edit: 1,
};
TabStore.propTypes = {
  store: PropTypes.object,
  reset: PropTypes.bool,
  initialActiveTab: PropTypes.string,
  mode: PropTypes.oneOf(['inline', 'horizontal']),
};

const STORE_MENU_ITEMS = (
  store,
  basePath,
  history,
  handleOpenModalKey,
  handleStoreArchive,
  plan,
) => [
  {
    key: 'store',
    label: 'Store',
    icon: <Svg src={storeIcon} />,
    onClick: () => history.push(basePath),
  },
  {
    key: 'signage',
    label: 'Signage',
    icon: <Svg src={signageIcon} />,
    onClick: () => history.push(`${basePath}/signage`),
  },
  {
    key: 'sales',
    label: 'Sales',
    icon: <Activity />,
    onClick: () => history.push(`${basePath}/sales`),
  },
  {
    key: 'manage',
    label: 'Manage',
    type: 'submenu',
    icon: <Settings />,
    items: [
      {
        key: 'edit',
        label: 'Edit',
        onClick: () => handleOpenModalKey(MODAL_KEYS.edit),
      },
      {
        key: 'duplicate',
        label: 'Duplicate',
        onClick: () => handleOpenModalKey(MODAL_KEYS.duplicate),
      },
      {
        key: 'archive',
        label: (
          <DeactivateStoreLink
            storeId={store?.id}
            onChange={handleStoreArchive}
            status={store?.isActive}
            color="textDark"
          />
        ),
      },
    ],
  },
  {
    key: 'divider-1',
    type: 'divider',
  },
  {
    key: 'support-submenu-store',
    label: 'Support',
    type: 'submenu',
    icon: <HelpCircle />,
    items: [
      {
        key: 'Best Practices',
        label: 'Best Practices',
        icon: <HelpCircle />,
        onClick: () => {
          window.open(
            'https://thehostcohelp.zendesk.com/hc/en-us/categories/15225750829325-Best-Practices',
            '_blank',
          );
        },
      },
      {
        key: 'explore-store',
        label: 'Example store',
        icon: <Eye />,
        onClick: () => {
          window.open('https://app.thehost.co/store/19/', '_blank');
        },
      },
      {
        key: 'contact-us',
        label: 'Contact us',
        icon: <Svg src={contactIcon} />,
        onClick: () => {
          window.open('https://www.thehost.co/contact', '_blank');
        },
      },
    ],
  },
  {
    key: 'pro-upgrade-link',
    label: getUpgradeString(plan),
    hidden: plan === PaymentPlan.Enterprise,
    icon: <Svg src={rocketIcon} />,
    onClick: () => {
      history.push('/pricing/');
    },
    className: 'menu-item-pro-upgrade',
  },
];

const getUpgradeString = (plan) => {
  switch (plan) {
    case 'BASIC':
      return 'Upgrade to Pro';
    case 'PRO':
      return 'Upgrade to Pro Plus';
    case 'PRO_PLUS':
      return 'Upgrade to Enterprise';
    default:
      return '';
  }
};
