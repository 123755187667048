import React from 'react';
import { Row, Col } from 'antd';
import { Button, TextFieldPassword } from 'ui-kit';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordValidationSchema } from './utils';
import { getFieldError } from 'components/NewProductStepper/utils';

interface InitialValues {
  password: string;
  confirmPassword: string;
}

interface Props {
  initialValues?: InitialValues;
  onSubmit: (values: InitialValues) => Promise<void>;
}

const defaultValues: InitialValues = {
  password: '',
  confirmPassword: '',
};

export default function ResetPasswordForm({ initialValues = defaultValues, onSubmit }: Props) {
  const { control, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(resetPasswordValidationSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row gutter={[0, 16]}>
        <Col xs={24}>
          <Controller
            control={control}
            name="password"
            render={({ field: { ref, ...rest }, fieldState }) => (
              <TextFieldPassword
                label="New Password"
                required
                fullWidth
                inputRef={ref}
                {...getFieldError(fieldState)}
                {...rest}
              />
            )}
          />
        </Col>
        <Col xs={24}>
          <Controller
            control={control}
            name="confirmPassword"
            render={({ field: { ref, ...rest }, fieldState }) => (
              <TextFieldPassword
                label="Confirm Password"
                required
                fullWidth
                inputRef={ref}
                {...getFieldError(fieldState)}
                {...rest}
              />
            )}
          />
        </Col>
        <Col xs={24}>
          <Button
            variant="contained"
            fullWidth
            disabled={!formState.isValid}
            loading={formState.isSubmitting}
            type="submit"
          >
            Reset password
          </Button>
        </Col>
      </Row>
    </form>
  );
}
