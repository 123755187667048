import * as Yup from 'yup';

export const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string().required('This field is required'),
  confirmPassword: Yup.string()
    .test('smae-password', `Password doesn't match.`, function (value) {
      if (this.parent.password && value !== this.parent.password) return false;
      return true;
    })
    .required('This field is requires'),
});
