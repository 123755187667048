import useSWR from 'swr';
import { API } from '../consts';
import { get, post } from './api';
import _ from 'lodash';
import { IntegrationType } from './types/partners';

const getNoPropertiesFoundMsg = (integration) => {
  return `We couldn’t find any new properties. Please ensure all of your ${integration} properties are up-to-date.`;
};

const getErrorFetchingMsg = (integration) => {
  return `Error connecting to ${integration}.`;
};

export const ownerRezApi = {
  authenticate: () => {
    window.location.href = `${API.root}auth/login/ownerrez/`;
  },
  getUser: async function () {
    return get('/services/ownerrez/user/');
  },
  getProperties: async function () {
    const response = await get('/services/ownerrez/properties/', null, {
      params: {
        includeDisabled: false,
      },
    });
    let error = null;

    if (!response?.ok) {
      error = new Error(getErrorFetchingMsg(IntegrationType.OWNERREZ));
      error.info = [];
      throw error;
    }

    // Removed filter for USA property
    const properties = response?.data;

    if (!properties.length) {
      error = new Error(getNoPropertiesFoundMsg(IntegrationType.OWNERREZ));
      error.status = 'STAY_IN_IMPORT_PROPERTIES';
      error.info = [];
      throw error;
    }

    return response;
  },
  usePropertyList: function () {
    const key = '/overview/partners/ownerrez/properties';
    const { data, error } = useSWR(key, () => this.getProperties(), { revalidateOnFocus: false });
    return {
      data: data?.data ?? error,
      isLoading: !error && !data,
      isError: !data?.ok || Boolean(error),
      error: _.get(error, 'message'),
    };
  },
};

export const hospitableConnectApi = {
  authenticate: async () => {
    let error = null;
    const connection = await post('/services/hospitableconnect/connect/');
    if (!connection.ok) {
      error = new Error('Error connecting to Hospitable Connect.');
      error.info = [];
      throw error;
    }
    window.location.href = connection.data.returnUrl;
  },
  getProperties: async function () {
    const response = await get('/services/hospitableconnect/listings/');
    let error = null;
    if (!response.ok) {
      error = new Error(getErrorFetchingMsg(IntegrationType.HOSPITABLE_CONNECT));
      error.info = [];
      throw error;
    }
    const properties = response.data;
    if (!properties.length) {
      error = new Error(getNoPropertiesFoundMsg(IntegrationType.HOSPITABLE_CONNECT));
      error.info = [];
      throw error;
    }
    return response;
  },
  usePropertyList: function () {
    const key = '/overview/partners/hospitableconnect/properties';
    const { data, error } = useSWR(key, () => this.getProperties(), { revalidateOnFocus: false });
    return {
      data: data?.data ?? error,
      isLoading: !error && !data,
      isError: !data?.ok || Boolean(error),
      error: _.get(error, 'message'),
    };
  },
};

export const hospitableApi = {
  authenticate: async () => {
    window.location.href = `${API.root}auth/login/hospitable/`;
  },
  getProperties: async function () {
    const response = await get('/services/hospitable/properties/');
    let error = null;
    if (!response.ok) {
      error = new Error(getErrorFetchingMsg(IntegrationType.HOSPITABLE));
      error.info = [];
      throw error;
    }
    const properties = response.data;
    if (!properties.length) {
      error = new Error(getNoPropertiesFoundMsg(IntegrationType.HOSPITABLE));
      error.info = [];
      throw error;
    }
    return response;
  },
  usePropertyList: function () {
    const key = '/overview/partners/hospitable/properties';
    const { data, error } = useSWR(key, () => this.getProperties(), { revalidateOnFocus: false });
    return {
      data: data?.data ?? error,
      isLoading: !error && !data,
      isError: !data?.ok || Boolean(error),
      error: _.get(error, 'message'),
    };
  },
};

export const guestyApi = {
  getGuestyOauth: async function () {
    const response = await get('/services/guesty/oauth/get/');
    let error = null;

    if (!response?.ok) {
      error = new Error('');
      error.info = [];
      throw error;
    }
    return response;
  },

  useGuestyOauth: function () {
    const key = '/overview/partners/landing/guesty';
    const { data, error } = useSWR(key, () => this.getGuestyOauth(), { revalidateOnFocus: false });
    return {
      data: data?.data ?? error,
      isLoading: !error && !data,
      isError: !data?.ok || Boolean(error),
    };
  },

  createOauth: async function ({ owner, integrationToken }) {
    const url = '/services/guesty/oauth/';
    return await post(url, { user: owner, integrationToken });
  },

  getProperties: async function () {
    const response = await get('/services/guesty/properties/');
    let error = null;
    if (!response.ok) {
      error = new Error(getErrorFetchingMsg(IntegrationType.GUESTY));
      error.info = [];
      throw error;
    }
    const properties = response.data;
    if (!properties.length) {
      error = new Error(getNoPropertiesFoundMsg(IntegrationType.GUESTY));
      error.info = [];
      throw error;
    }

    return response;
  },
  usePropertyList: function () {
    const key = '/overview/partners/guesty/properties';
    const { data, error } = useSWR(key, () => this.getProperties(), { revalidateOnFocus: false });
    return {
      data: data?.data ?? error,
      isLoading: !error && !data,
      isError: !data?.ok || Boolean(error),
      error: _.get(error, 'message'),
    };
  },
};

export const hostawayApi = {
  getHostawayOauth: async function () {
    const response = await get('/services/hostaway/oauth/get/');
    let error = null;

    if (!response?.ok) {
      error = new Error('');
      error.info = [];
      throw error;
    }
    return response;
  },

  useHostawayOauth: function () {
    const key = '/overview/partners/landing/hostaway';
    const { data, error } = useSWR(key, () => this.getHostawayOauth(), {
      revalidateOnFocus: false,
    });
    return {
      data: data?.data ?? error,
      isLoading: !error && !data,
      isError: !data?.ok || Boolean(error),
    };
  },

  createOauth: async function ({ accountId, apiKey }) {
    const url = '/services/hostaway/oauth/';
    return await post(url, { accountId, apiKey });
  },

  getProperties: async function () {
    const response = await get('/services/hostaway/properties/');
    let error = null;
    if (!response.ok) {
      error = new Error(getErrorFetchingMsg(IntegrationType.HOSTAWAY));
      error.info = [];
      throw error;
    }
    const properties = response.data;
    if (!properties.length) {
      error = new Error(getNoPropertiesFoundMsg(IntegrationType.HOSTAWAY));
      error.info = [];
      throw error;
    }

    return response;
  },
  usePropertyList: function () {
    const key = '/overview/partners/hostaway/properties';
    const { data, error } = useSWR(key, () => this.getProperties(), { revalidateOnFocus: false });

    return {
      data: data?.data ?? error,
      isLoading: !error && !data,
      isError: !data?.ok || Boolean(error),
      error: _.get(error, 'message'),
    };
  },
};

export const lodgifyApi = {
  getLodgifyOauth: async function () {
    const response = await get('/services/lodgify/oauth/get/');
    let error = null;

    if (!response?.ok) {
      error = new Error('');
      error.info = [];
      throw error;
    }
    return response;
  },

  useLodgifyOauth: function () {
    const key = '/overview/partners/landing/lodgify';
    const { data, error } = useSWR(key, () => this.getLodgifyOauth(), {
      revalidateOnFocus: false,
    });
    return {
      data: data?.data ?? error,
      isLoading: !error && !data,
      isError: !data?.ok || Boolean(error),
    };
  },

  createOauth: async function ({ apiKey }) {
    const url = '/services/lodgify/oauth/';
    return await post(url, { apiKey });
  },

  getProperties: async function () {
    const response = await get('/services/lodgify/properties/');
    let error = null;
    if (!response.ok) {
      error = new Error(getErrorFetchingMsg(IntegrationType.LODGIFY));
      error.info = [];
      throw error;
    }
    const properties = response.data;
    if (!properties.length) {
      error = new Error(getNoPropertiesFoundMsg(IntegrationType.LODGIFY));
      error.info = [];
      throw error;
    }

    return response;
  },
  usePropertyList: function () {
    const key = '/overview/partners/lodgify/properties';
    const { data, error } = useSWR(key, () => this.getProperties(), { revalidateOnFocus: false });
    return {
      data: data?.data ?? error,
      isLoading: !error && !data,
      isError: !data?.ok || Boolean(error),
      error: _.get(error, 'message'),
    };
  },
};
