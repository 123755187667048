import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import _isEmpty from 'lodash/isEmpty';
import { TextField, FormControl, UploadImage, Button } from 'ui-kit';
import StateSelect from 'components/Form/StateSelect';
import { getUpdateValues } from 'utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationStoreSchema } from './utils';
import './StoreBaseForm.scss';
import CountrySelectV2 from 'components/Form/CountrySelectV2';
interface InitialValues {
  storeName: string;
  street1?: string;
  postalCode: string;
  city: string;
  country: string;
  state: string;
  image?: string;
  description: string;
}

const defaultValues = {
  storeName: '',
  street1: '',
  postalCode: '',
  city: '',
  country: 'US',
  state: '',
  description:
    'Welcome to our store! Purchase all items before use. Details and item location will be on email receipt. Enjoy :-)',
};

export interface StoreBaseFormProps {
  onSubmit: (values: InitialValues) => Promise<void> | void;
  onCancel: () => void;
  initialValues?: InitialValues;
  mode?: 'duplicate' | 'new' | 'edit';
}

export default function StoreForm({
  onSubmit,
  onCancel,
  initialValues = defaultValues,
  mode,
}: StoreBaseFormProps) {
  const { handleSubmit, control, formState, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    shouldFocusError: false,
    resolver: yupResolver(validationStoreSchema),
  });

  const disabledSubmittion = _isEmpty(formState.dirtyFields);

  useEffect(() => {
    if (mode === 'duplicate') {
      setValue('storeName', `Copy ${initialValues.storeName}`, { shouldDirty: true });
    }
  }, [mode]);

  const changeCountry = (newValue) => {
    setValue('state', '', { shouldDirty: true });
    setValue('country', newValue, { shouldDirty: true });
  };

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit((data) =>
        onSubmit(getUpdateValues(data, mode !== 'edit' ? {} : formState.dirtyFields)),
      )}
      className="StoreBaseForm"
    >
      <div className="StoreBaseForm-content">
        <div className="StoreBaseForm-section-one">
          <div className="StoreBaseForm-section-one__details">
            <Controller
              control={control}
              name="storeName"
              render={({ field: { ref, ...rest }, fieldState }) => (
                <TextField
                  label="Store name"
                  required
                  fullWidth
                  inputRef={ref}
                  error={Boolean(fieldState.error)}
                  errorMessage={fieldState.error?.message}
                  {...rest}
                />
              )}
            />
            <div className="StoreBaseForm-section-one__street">
              <Controller
                control={control}
                name="country"
                render={({ field, fieldState }) => (
                  <FormControl
                    label="Your rental’s country"
                    required
                    error={Boolean(fieldState.error)}
                    errorMessage={fieldState.error?.message}
                  >
                    <CountrySelectV2
                      onChange={(newValue) => changeCountry(newValue)}
                      value={field.value}
                    />
                  </FormControl>
                )}
              />
              {getValues('country') === 'US' && (
                <Controller
                  control={control}
                  name="state"
                  render={({ field, fieldState }) => (
                    <FormControl
                      label="Your rental’s state"
                      required
                      error={Boolean(fieldState.error)}
                      errorMessage={fieldState.error?.message}
                    >
                      <StateSelect getCountry={() => 'US'} {...field} />
                    </FormControl>
                  )}
                />
              )}
            </div>
            <div className="StoreBaseForm-section-one__address">
              <Controller
                control={control}
                name="city"
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <TextField
                    label="Your rental's city"
                    required
                    inputRef={ref}
                    error={Boolean(fieldState.error)}
                    errorMessage={fieldState.error?.message}
                    {...rest}
                  />
                )}
              />

              <Controller
                control={control}
                name="street1"
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <TextField
                    label="Your rental's street address"
                    inputRef={ref}
                    error={Boolean(fieldState.error)}
                    errorMessage={fieldState.error?.message}
                    {...rest}
                  />
                )}
              />
              <Controller
                control={control}
                name="postalCode"
                render={({ field: { ref, ...rest }, fieldState }) => (
                  <TextField
                    label="Your rental's zip code"
                    inputRef={ref}
                    required
                    error={Boolean(fieldState.error)}
                    errorMessage={fieldState.error?.message}
                    {...rest}
                  />
                )}
              />
            </div>
          </div>
          <Controller
            control={control}
            name="image"
            render={({ field, fieldState }) => (
              <FormControl
                label="Store photo/logo"
                error={Boolean(fieldState.error)}
                errorMessage={fieldState.error?.message}
              >
                <UploadImage previewUrl={initialValues.image} {...field} />
              </FormControl>
            )}
          />
        </div>
        <Controller
          control={control}
          name="description"
          render={({ field: { ref, ...rest }, fieldState }) => (
            <TextField
              label="Store description"
              helperText="Let your guests know what makes your store unique and exciting. This is a great place to describe your brand products or local services."
              inputRef={ref}
              required
              fullWidth
              rows={6}
              multiline
              showCount
              error={Boolean(fieldState.error)}
              errorMessage={fieldState.error?.message}
              inputProps={{
                maxLength: 500,
              }}
              {...rest}
            />
          )}
        />
      </div>
      <div className="StoreBaseForm-footer">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          loading={formState.isSubmitting}
          disabled={disabledSubmittion}
        >
          {initialValues.storeName ? 'Save changes' : 'Create store'}
        </Button>
        {initialValues.storeName && (
          <Button variant="text" color="primary" fullWidth onClick={onCancel}>
            Cancel
          </Button>
        )}
      </div>
    </form>
  );
}
