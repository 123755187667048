import React from 'react';
import Alert from 'components/Alert';
import { Link } from 'ui-kit';
import { AlertTriangle } from 'react-feather';
import './RequestBanner.scss';

type Props = {
  visible?: boolean;
};

export default function RequestBanner({ visible = false }: Props) {
  return (
    <div className="request_banner-container">
      {visible && (
        <Alert type="error" className="request_banner" showIcon icon={<AlertTriangle size={18} />}>
          You have requests that require your approval.&nbsp;
          <Link to="/overview/requests/">Click here to manage them.</Link>
        </Alert>
      )}
    </div>
  );
}
