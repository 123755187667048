/* eslint-disable react/prop-types */
import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { PartnerRoute } from './PartnerRoute';
import PartnerRoutes from './PartnerRoutes';
import { URLS } from './urls';

import OrdersList from 'pages/OrdersList';

const StoreList = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/StoreList'
  ),
);
const SettingsAccountPage = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'pages/settings/Account'
  ),
);
const Payouts = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/Dashboard/Payouts/'
  ),
);

const RequestList = lazy(() =>
  import(
    /* webpackChunkName: "overview" */
    'components/RequestList'
  ),
);

const OverviewRoutes = () => {
  return (
    <Switch>
      <Route path="/overview/stores/" component={StoreList} />
      <Route path="/overview/payouts/" component={Payouts} />
      <Route path={URLS.ACCOUNT} component={SettingsAccountPage} />
      <Route path="/overview/requests/" component={RequestList} />
      <Route path={URLS.ORDERS} component={OrdersList} />
      <PartnerRoute path="/overview/partners/">
        <PartnerRoutes />
      </PartnerRoute>
      <Route>
        <Redirect to="/overview/stores/" />
      </Route>
    </Switch>
  );
};

export default OverviewRoutes;
