import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import logoSvg from './logo.svg';

export default function Logo({ className, logo, ...rest }) {
  if (!logo) {
    return <img className={classNames(className, 'Logo')} src={logoSvg} {...rest} />;
  }
  return (
    <img className={classNames(className, 'Logo')} width={50} height={50} src={logo} {...rest} />
  );
}

Logo.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
};
