import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import { message } from 'antd';
import _ from 'lodash';
import { useAuth } from 'contexts/auth/auth';

import { Typography } from 'ui-kit';
import CreateStoresConfirmModal from 'components/Partners/CreateStoresButtonConfirmModal';
import LoadingModal from 'components/LoadingModal';
import OverviewLayout from 'components/OverviewLayout/OverviewLayout';
import ImportStoresTable from 'components/Partners/ImportStoresTable/ImportStoresTable';
import ErrorMessage from 'components/ErrorMessage';

import { getIntegrationApi, usePollingImportStores } from 'components/Partners/utils';
import { useHistory, useParams } from 'react-router-dom';
import { getStoreList } from 'api/owners';

import './styles.scss';

const IntegrationPage = () => {
  const { company } = useParams<{ company: string }>();

  const { id: ownerId } = useAuth();
  const [isCreatingStores, setIsCreatingStores] = useState(false);
  const [massStoreRequestId, setMassStoreRequestId] = useState<number | null>(null);

  const api = useMemo(() => getIntegrationApi({ company }), [company]);

  const { data, isLoading, isError, error } = api.usePropertyList();

  const history = useHistory();

  const { invalidate: updateStoreList } = getStoreList(ownerId);

  const { setValue, handleSubmit, ...methods } = useForm({
    mode: 'onChange',
    defaultValues: { listings: [] },
    shouldFocusError: true,
  });

  // Set form values when data is fetched
  useEffect(() => {
    if (_.isEmpty(error)) {
      setValue('listings', data);
    } else {
      setValue('listings', []);
    }
  }, [data, error]);

  const { isTaskReady } = usePollingImportStores(massStoreRequestId);

  useEffect(() => {
    if (isTaskReady) {
      updateStoreList();
      setIsCreatingStores(false);
      message.info('Your stores have been imported successfully!');
      history.push(`/overview/stores/?intro=${company}`);
    }
  }, [isTaskReady, updateStoreList, company]);

  useEffect(() => {
    if (!isLoading && isError) {
      message.error(
        <ErrorMessage
          message={
            error ||
            `Something went wrong! Please check if you connected TheHostCo in your ${_.capitalize(
              company,
            )} Marketplace or`
          }
          action={() => window.open('https://www.thehost.co/contact', '_blank')}
          actionLabel="contact support"
        />,
        10,
      );
    }
  }, [isLoading, company]);

  if (isCreatingStores) {
    return (
      <LoadingModal
        title="Creating Stores"
        topCopy="Thank you for your patience while we get your stores."
        bottomCopy="Please don’t leave or refresh this page until the process is done."
        isVisible={isCreatingStores}
      />
    );
  }

  return (
    <FormProvider setValue={setValue} handleSubmit={handleSubmit} {...methods}>
      <OverviewLayout>
        <Helmet>
          <title>Partnered with {_.capitalize(company)} | The Host Co</title>
        </Helmet>
        <div className="Integration">
          {isLoading ? (
            <LoadingModal
              title="Importing properties..."
              topCopy="Thank you for your patience while we get your properties."
              bottomCopy="Please don't leave or refresh this page until the process is done."
              isVisible={isLoading}
            />
          ) : (
            <div className="Integration_content">
              <div className="Integration_header">
                <div className="Integration_header-text">
                  <Typography variant="h4" className="Integration_header-title">
                    Select Properties
                  </Typography>
                  <Typography variant="body2">
                    Select which properties you&apos;d like to create stores from.
                  </Typography>
                  <Typography variant="body2">
                    (You can do this again later to import even more properties)
                  </Typography>
                </div>
                <div className="Integration_header-button">
                  <CreateStoresConfirmModal
                    ownerId={ownerId}
                    setIsCreatingStores={setIsCreatingStores}
                    setMassStoreRequestId={setMassStoreRequestId}
                  />
                </div>
              </div>
              <ImportStoresTable listings={!isError ? data : []} />
            </div>
          )}
        </div>
      </OverviewLayout>
    </FormProvider>
  );
};

export default IntegrationPage;
