import React, { useMemo, useState, useRef, useEffect } from 'react';
import { ChevronUp as ChevronUpIcon } from 'react-feather';
import clsx from 'clsx';
import './Collapse.scss';

const BASE_CLASS = 'ui-kit-collapse';

type Color =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'success'
  | 'textDark'
  | 'textLight'
  | 'textGray';

interface Props {
  header: (isCollapse: boolean) => React.ReactNode;
  children: React.ReactNode;
  initialCollpase?: boolean;
  color?: Color;
  onCollapse?: (status: boolean) => void;
}

export default function Collapse({
  children,
  header,
  initialCollpase = true,
  color = 'secondary',
  onCollapse,
}: Props) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isCollapse, setCollapse] = useState(() => initialCollpase);
  const contetHeight = useMemo(
    () => (!isCollapse ? contentRef.current?.clientHeight : 0),
    [isCollapse],
  );

  const toogleCollapse = () => {
    setCollapse((state) => !state);
  };

  useEffect(() => {
    onCollapse?.(isCollapse);
  }, [isCollapse]);

  return (
    <div className={clsx(BASE_CLASS, `${BASE_CLASS}-${color}`)}>
      <div role="button" className={`${BASE_CLASS}-header`} onClick={toogleCollapse}>
        {header(isCollapse)}
        <ChevronUpIcon
          className={clsx(`${BASE_CLASS}-header__icon`, {
            [`${BASE_CLASS}-header__icon-active`]: isCollapse,
          })}
        />
      </div>
      <div style={{ height: contetHeight }} className={`${BASE_CLASS}-content`}>
        <div ref={contentRef} className={`${BASE_CLASS}-content-wrapper`}>
          {children}
        </div>
      </div>
    </div>
  );
}
