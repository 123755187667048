import React from 'react';
import PropTypes from 'prop-types';
import Link from 'ui-kit/Link';
import { classNames } from 'utils';
import './Footer.scss';

export default function Footer({ className }) {
  return (
    <>
      <div className={classNames(className, 'DashboardFooter')}>
        <Link className="DashboardFooter_link" href="https://www.thehost.co/prohibited-items">
          Prohibited Items
        </Link>
        <Link className="DashboardFooter_link" href="https://www.thehost.co/privacy-policy">
          Privacy Policy
        </Link>
      </div>
    </>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
};
