import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as AntCheckbox } from 'antd';
import { classNames } from 'utils';
import './Checkbox.scss';

export default function Checkbox({ className, ...rest }) {
  return <AntCheckbox className={classNames(className, 'Checkbox')} {...rest} />;
}

Checkbox.propTypes = {
  className: PropTypes.string,
};
