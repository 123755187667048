import React, { useCallback, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import Stack from 'ui-kit/Stack';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import OverviewLayout from 'components/OverviewLayout/OverviewLayout';
import Table from 'components/Table';
import { Collapse, FormControl, Link, Select, Typography } from 'ui-kit';
import { formatCurrency } from 'utils';
import { useOrders } from 'api/order_sdk';
import { OrderStatus, OrderStatusMap } from 'api/types/order';

import { STATUSES_OPTIONS } from './constants';
import './styles.scss';
import { useAuth } from 'contexts/auth/auth';
import { getStoreList } from 'api/owners';
import { Col, DatePicker, Pagination, Row } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { getLocalDatetime } from 'utils/datetime';
import OrdersExportButton from 'components/OrdersExportButton';

const { RangePicker } = DatePicker;

dayjs.extend(advancedFormat);

const OrdersList = () => {
  const [selectedStore, setSelectedStore] = useState(undefined);
  const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  const {
    data: ordersData,
    currentPage,
    count,
    setPage,
    setCurrentPage,
    setOffset,
  } = useOrders({
    storeId: selectedStore?.value,
    status: selectedStatus?.value,
    startDate: startDate ? getLocalDatetime(startDate, 'YYYY-MM-DD') : undefined,
    endDate: endDate ? getLocalDatetime(endDate, 'YYYY-MM-DD') : undefined,
  });

  const orders = useMemo(() => {
    return ordersData?.results || [];
  }, [ordersData]);

  const { id: ownerId } = useAuth();
  const {
    data: { stores },
  } = getStoreList(ownerId);

  const storesOptions = useMemo(
    () =>
      stores?.map((store) => ({
        label: store.storeName,
        value: store.id,
      })) || [],
    [stores],
  );

  const handleChangeStore = useCallback(
    (_, option) => {
      setSelectedStore(option);
      setOffset(0);
      setCurrentPage(1);
    },
    [setOffset, setCurrentPage],
  );

  const handleChangeStatus = useCallback(
    (_, option) => {
      setSelectedStatus(option);
      setOffset(0);
      setCurrentPage(1);
    },
    [setOffset, setCurrentPage],
  );

  const onChangeRange = useCallback(
    (arr) => {
      if (_.isNull(arr)) {
        setStartDate(undefined);
        setEndDate(undefined);
        setOffset(0);
        setCurrentPage(1);
        return;
      }

      const start = new Date(arr[0]);
      const end = new Date(arr[1]);
      setStartDate(start);
      setEndDate(end);
      setOffset(0);
      setCurrentPage(1);
    },
    [setOffset, setCurrentPage],
  );

  return (
    <OverviewLayout>
      <Helmet>
        <title>Recent Orders | The Host Co</title>
      </Helmet>
      <p className="page-title">Recent Orders</p>
      <Collapse
        header={() => <Typography variant="h6">Filters</Typography>}
        initialCollpase={false}
      >
        <div className="filters-box">
          <Row gutter={[36, 12]}>
            <Col md={8} span={24}>
              <FormControl label="Store name" fullWidth>
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) => {
                    const inputValue = input.toLowerCase();
                    return option.children.toLowerCase().indexOf(inputValue) >= 0;
                  }}
                  onChange={handleChangeStore}
                  placeholder="Select Store"
                  value={selectedStore?.value}
                  options={storesOptions}
                />
              </FormControl>
            </Col>
            <Col md={8} span={24}>
              <FormControl label="Status" fullWidth>
                <Select
                  allowClear
                  onChange={handleChangeStatus}
                  placeholder="Select Status"
                  value={selectedStatus?.value}
                  options={STATUSES_OPTIONS}
                />
              </FormControl>
            </Col>
            <Col md={8} span={24}>
              <FormControl label="Date of purchase" fullWidth>
                <RangePicker
                  allowClear
                  size="large"
                  value={[moment(startDate), moment(endDate)]}
                  onChange={onChangeRange}
                />
              </FormControl>
            </Col>
          </Row>
        </div>
      </Collapse>
      <Stack flexDirection="column" alignItems="center">
        <Table
          className="orders-table"
          dataSource={orders}
          scroll={{ x: 500 }}
          rowKey="id"
          pagination={false}
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <MinusCircleOutlined
                  style={{ fontSize: '18px' }}
                  onClick={(e) => onExpand(record, e)}
                />
              ) : (
                <PlusCircleOutlined
                  style={{ fontSize: '18px' }}
                  onClick={(e) => onExpand(record, e)}
                />
              ),
            expandedRowRender: (record) => (
              <Table
                columns={[
                  { title: 'Product Name', dataIndex: 'product_name', key: 'productName' },
                  {
                    title: 'Quantity',
                    dataIndex: 'quantity',
                    key: 'quantity',
                  },
                  {
                    title: 'Date of Service',
                    dataIndex: 'delivery_date',
                    key: 'deliveryDate',
                    render: (text) => {
                      if (text) {
                        return (
                          <Typography variant="body2">
                            {dayjs(text).format('Do MMMM YYYY')}
                          </Typography>
                        );
                      } else {
                        return <Typography variant="body2">n/a</Typography>;
                      }
                    },
                  },
                  {
                    title: 'Price Per Item',
                    key: 'price',
                    dataIndex: 'price',
                    render: (text, record) => (
                      <Typography variant="body2">
                        {formatCurrency(text, record.currency)}
                      </Typography>
                    ),
                  },
                ]}
                dataSource={record.products}
                pagination={false}
                className="DashboardSales_subtable"
              />
            ),
          }}
          columns={[
            {
              title: 'Sale Number',
              dataIndex: 'order_number',
              key: 'order_number',
              render: (text, record) => (
                <Link href={`/dashboard/store/${record.store?.id}/sales/${text}/`}>{text}</Link>
              ),
            },
            {
              title: 'Store',
              dataIndex: 'store.store_name',
              key: 'store_name',
              render: (text, record) => (
                <Typography variant="body1" className="store-name-cell">
                  {record.store?.store_name}
                </Typography>
              ),
            },
            {
              title: 'Guest Name',
              dataIndex: 'guest_name',
              key: 'guest_name',
              render: (text) => (
                <Typography variant="body1" className="guest-name-cell">
                  {text}
                </Typography>
              ),
            },
            {
              title: 'Date of Purchase',
              dataIndex: 'date_created',
              key: 'date_created',
              render: (text) => (
                <Typography variant="body2">{dayjs(text).format('Do MMMM YYYY')}</Typography>
              ),
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              render: (text) => <Typography variant="body2">{OrderStatusMap[text]}</Typography>,
            },
            {
              title: 'Guest Paid',
              dataIndex: 'grand_total',
              key: 'grand_total',
              render: (text, record) => (
                <Typography variant="body2">{formatCurrency(text, record.currency)}</Typography>
              ),
            },
            {
              title: 'Your Profit',
              dataIndex: 'your_profit',
              key: 'your_profit',
              render: (text, record) => (
                <Typography variant="body2">
                  {formatCurrency(
                    record.status === OrderStatus.CANCEL ? '0.00' : text,
                    record.currency,
                  )}
                </Typography>
              ),
            },
          ]}
        />
        <Pagination onChange={setPage} current={currentPage} total={count} />
        <div style={{ alignSelf: 'flex-end' }}>
          <OrdersExportButton
            selectedFilters={{
              selectedStore: selectedStore?.value,
              selectedStatus: selectedStatus?.value,
              startDate,
              endDate,
            }}
            disabled={orders.length === 0}
          />
        </div>
      </Stack>
    </OverviewLayout>
  );
};

export default OrdersList;
