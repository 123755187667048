import React from 'react';
import clsx from 'clsx';
import './Card.scss';

interface Props {
  children?: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const BASE_CLASS = 'ui-kit-card';

const Card: React.FC<Props> = ({ children, className: externalClassName, onClick, ...rest }) => {
  const role = onClick ? 'button' : 'article';
  const className = clsx(BASE_CLASS, `${BASE_CLASS}-role-${role}`, externalClassName);
  return (
    <div className={className} {...(onClick ? { onClick, role } : {})} {...rest}>
      {children}
    </div>
  );
};

export default Card;
