import React from 'react';
import PropTypes from 'prop-types';
import { Table as AntTable } from 'antd';
import { classNames } from 'utils';
import './Table.scss';

export default function Table({ className, ...rest }) {
  return (
    <AntTable
      className={classNames(className, 'Table')}
      pagination={{
        position: ['bottomCenter'],
      }}
      {...rest}
    />
  );
}

Table.propTypes = {
  className: PropTypes.string,
};
