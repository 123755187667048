import React from 'react';
import { Divider } from 'antd';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import { Card, CardContent, Typography } from 'ui-kit';
import './UnauthLayout.scss';

interface Props {
  title?: string;
  children: React.ReactNode;
}

export default function UnauthLayout({ children, title }: Props) {
  return (
    <section className="UnauthLayout">
      <Header />
      <main className="UnauthLayout-container">
        <div className="UnauthLayout-container__content">
          <Card className="UnauthLayout-container__content-card">
            <CardContent>
              {title && (
                <div className="UnauthLayout-container__content-card-title">
                  <Typography variant="h6">{title}</Typography>
                  <Divider />
                </div>
              )}
              {children}
            </CardContent>
          </Card>
        </div>
      </main>
      <Footer />
    </section>
  );
}
