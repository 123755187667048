import React, { useMemo } from 'react';
import placeholder from 'components/Dashboard/Store/Products/images/placeholder.png';
import { Checkbox, TextField } from 'ui-kit';
import { Row, Col } from 'antd';
import EmptyData from 'components/EmptyData';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { ImportedStore } from 'api/types/store';

import './ImportStoresTable.scss';

type Props = {
  listings: ImportedStore[];
};

export default function ImportStoresTable({ listings }: Props) {
  const { control, setValue } = useFormContext();

  // Watch all listings to track their selected state
  const listingsWatch = useWatch({ control, name: 'listings' });

  // Handle the "Select All" checkbox
  const selectAll = (event) => {
    const checked = event.target.checked;
    listingsWatch.forEach((_, index) => {
      setValue(`listings.${index}.selected`, checked);
    });
  };

  const isAllSelected = useMemo(
    () => listingsWatch?.every((listing) => listing.selected) || false,
    [listingsWatch],
  );

  return (
    <div className="ImportStores_table">
      <Row className="ImportStores_table_row header-row">
        <Col className="ImportStores_table_col header-col checkbox">
          <Checkbox
            id="all"
            className="ImportStores_table_checkbox"
            onChange={selectAll}
            checked={isAllSelected}
          >
            Select all
          </Checkbox>
        </Col>
        <Col className="ImportStores_table_col header-col photo">Photo</Col>
        <div className="ImportStores_table_col_group header-group">
          <Col className="ImportStores_table_col header-col name">Store Name</Col>
          <Col className="ImportStores_table_col header-col city">City</Col>
          <Col className="ImportStores_table_col header-col state">State</Col>
          <Col className="ImportStores_table_col header-col zip">Zip</Col>
        </div>
      </Row>
      <div className="ImportStores_table_contents">
        {!listings?.length ? (
          <EmptyData label="No properties found" />
        ) : (
          <form className="ImportStores_form">
            {listings?.map((property, index) => (
              <Row className="ImportStores_table_row contents-row" key={property.externalId}>
                <Col className="ImportStores_table_col contents-col checkbox">
                  <Controller
                    control={control}
                    name={`listings.${index}.selected`}
                    render={({ field: { name, value, onChange } }) => (
                      <Checkbox
                        id={property.externalId}
                        className="ImportStores_table_checkbox"
                        name={name}
                        checked={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Col>
                <Col className="ImportStores_table_col contents-col photo">
                  <Controller
                    control={control}
                    name={`listings.${index}.image`}
                    render={() => (
                      <img
                        src={property.image || placeholder}
                        alt="rental-photo"
                        width={80}
                        height={60}
                      />
                    )}
                  />
                </Col>
                <div className="ImportStores_table_col_group">
                  <Col className="ImportStores_table_col contents-col name">
                    <Controller
                      control={control}
                      name={`listings.${index}.storeName`}
                      render={({ field: { ref, ...rest }, fieldState }) => (
                        <TextField
                          required
                          fullWidth
                          inputRef={ref}
                          error={Boolean(fieldState.error)}
                          errorMessage={fieldState.error?.message}
                          {...rest}
                        />
                      )}
                    />
                  </Col>
                  <Col className="ImportStores_table_col contents-col city">
                    <Controller
                      control={control}
                      name={`listings.${index}.city`}
                      render={() => <p>{property.city}</p>}
                    />
                  </Col>
                  <Col className="ImportStores_table_col contents-col state">
                    <Controller
                      control={control}
                      name={`listings.${index}.state`}
                      render={() => <p>{property.state}</p>}
                    />
                  </Col>
                  <Col className="ImportStores_table_col contents-col zip">
                    <Controller
                      control={control}
                      name={`listings.${index}.postalCode`}
                      render={() => <p>{property.postalCode}</p>}
                    />
                  </Col>
                </div>
              </Row>
            ))}
          </form>
        )}
      </div>
    </div>
  );
}
