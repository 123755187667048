import clsx from 'clsx';
import React from 'react';
import './Spinner.scss';

type Size = 'small' | 'medium' | 'large';

interface Props {
  size?: Size;
}

const BASE_CLASS = 'ui-kit-spinner';

const Spinner: React.FC<Props> = ({ size = 'small' }) => {
  return <span className={clsx(BASE_CLASS, `${BASE_CLASS}-${size}`)} />;
};

export default Spinner;
