import React from 'react';
import './InputAdornment.scss';

const BASE_CLASS = 'ui-kit-input-adornment';

interface Props {
  children: React.ReactNode;
}

export default function InputAdornment({ children }: Props) {
  const renderContent = () => {
    if (!React.isValidElement(children)) {
      return <p>{children}</p>;
    }
    return children;
  };

  return <div className={BASE_CLASS}>{renderContent()}</div>;
}
