import React from 'react';
import { Spin as AntSpint, SpinProps } from 'antd';

interface Props extends SpinProps {
  children: React.ReactNode;
}

const Spin: React.FC<Props> = ({ children, ...rest }) => {
  return <AntSpint {...rest}>{children}</AntSpint>;
};

export default Spin;
