import React from 'react';
import { Button, Modal } from 'ui-kit';
import { ModalProps } from 'antd';
import './ConfirmModal.scss';

// COPIED FROM BUTTON TYPE
type Color =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'success'
  | 'textDark'
  | 'textLight'
  | 'textGray';

interface Props extends ModalProps {
  confirmLoading?: boolean;
  confirmText?: string;
  cancelText?: string;
  confirmHref?: string;
  confirmBtnType?: Color;
  onCancel: () => void;
  onConfirm?: () => void;
  children?: React.ReactNode;
}

const ConfirmModal = React.forwardRef<React.RefObject<HTMLElement>, Props>(
  (
    {
      confirmText = 'Confirm',
      confirmHref,
      confirmLoading = false,
      confirmBtnType = 'error',
      cancelText = 'Cancel',
      onCancel,
      onConfirm,
      children,
      ...rest
    },
    _ref,
  ) => (
    <Modal
      className="hc-confirm-modal"
      onCancel={onCancel}
      onOk={onConfirm}
      footer={[
        <Button key="cancel" className="cancel" variant="text" onClick={onCancel}>
          {cancelText}
        </Button>,
        <Button
          key="confirm"
          variant="contained"
          color={confirmBtnType}
          href={confirmHref}
          loading={confirmLoading}
          onClick={onConfirm}
        >
          {confirmText}
        </Button>,
      ]}
      {...rest}
    >
      {children}
    </Modal>
  ),
);

export default ConfirmModal;
