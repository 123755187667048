import React from 'react';
import { message } from 'antd';
import { createStore } from 'api/store';
import { Store } from 'api/types/store';
import { useAuth } from 'contexts/auth/auth';
import StoreModal, { StoreModalProps } from '../StoreModal';

interface Props extends Omit<StoreModalProps, 'onSubmit'> {
  onSuccess?: (store: Store) => void;
}

export default function NewStore({ onSuccess = () => null, ...rest }: Props) {
  const { id: owner, increseStores } = useAuth();
  const handleSubmit = (values) => {
    return createStore({ owner, country: 'US', ...values })
      .then((res) => {
        if (res.ok) {
          increseStores(1);
          onSuccess(res.data);
          return;
        }
        message.error(res.errors.fields?.[0]);
      })
      .catch(() => message.error('Ups there was an error, please try it later!'));
  };

  return <StoreModal mode="new" title="Create store" onSubmit={handleSubmit} {...rest} />;
}
