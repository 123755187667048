import { downloadFile } from './utilsV2';

export const downloadListOfListsAsCSV = ({
  data,
  filename,
}: {
  data: Array<Array<string | null | number>>;
  filename: string;
}) => {
  const fileType = 'text/csv;charset=utf-8;';

  // Convert data array to CSV format
  const csvContent = data
    .map((row) => row.map((item) => (item !== null ? `"${item}"` : '')).join(','))
    .join('\n');

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: fileType });
  const url = window.URL.createObjectURL(blob);

  // Trigger file download
  downloadFile(url, filename);
};
