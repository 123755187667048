/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { StoreContext } from 'contexts/store';
import { Redirect, useParams } from 'react-router-dom';
import { useAuth } from 'contexts/auth/auth';
import { useStoreDetails } from 'api/store';
import { Spin } from 'ui-kit';

export default function DashboardStoreProvider({ children }) {
  const { id: ownerId } = useAuth();
  const { storeId } = useParams<{ storeId: string }>();

  const { data: storeData, isLoading } = useStoreDetails(storeId);

  useEffect(() => {
    if (!isLoading && storeData) {
      localStorage.setItem('defaultStore', JSON.stringify(storeData));
    }
  }, [isLoading]);

  if (!isLoading && storeData.owner !== ownerId) {
    return <Redirect to="/overview/stores/" />;
  }

  return (
    <Spin spinning={isLoading}>
      {!isLoading && (
        <StoreContext.Provider value={{ ...storeData }}>{children}</StoreContext.Provider>
      )}
    </Spin>
  );
}
