import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui-kit';

export default function ErrorMessage({ message, action, actionLabel = 'Action' }) {
  return (
    <span>
      {message} {action && <Button onClick={action}>{actionLabel}</Button>}
    </span>
  );
}

ErrorMessage.propTypes = {
  message: PropTypes.string,
  action: PropTypes.func,
  actionLabel: PropTypes.string,
};
