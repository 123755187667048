import React, { useRef, useState } from 'react';
import { getStatesByCountryCode } from 'utils/geo';
import { CountryCode } from 'api/types/store';
import { Select } from 'ui-kit';

interface Props {
  value: string;
  getCountry: () => CountryCode;
  onChange: (value: CountryCode) => void;
}

const StateSelect = React.forwardRef<HTMLInputElement, Props>(
  ({ getCountry, value, onChange }, ref) => {
    const countrySelected = getCountry();
    const [query, setQuery] = useState('');
    const containerRef = useRef(null);
    const options = getStatesByCountryCode(countrySelected).map(({ isoCode, name }) => ({
      label: name,
      value: isoCode,
    }));
    options.push({ label: 'Other', value: 'OTHER' });
    return (
      <span ref={containerRef}>
        <Select
          placeholder="State..."
          showSearch
          filterOption={(input, option) => {
            const value = option.value.toLowerCase();
            const inputValue = input.toLowerCase();
            return value === inputValue || option.children.toLowerCase().indexOf(inputValue) >= 0;
          }}
          filterSort={(a) => {
            const val = a.value.toLowerCase();
            if (val === query.toLowerCase()) {
              return -1;
            }

            return 0;
          }}
          onSearch={setQuery}
          searchValue={query}
          disabled={!countrySelected}
          onChange={onChange}
          ref={ref}
          value={value}
          getPopupContainer={() => containerRef.current}
          options={options}
        />
      </span>
    );
  },
);

export default StateSelect;
