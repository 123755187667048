import React from 'react';
import TopNav from '../TopNav';
import PropTypes from 'prop-types';
import { useAuth } from '../../contexts/auth/auth';
import { useOwnerDetails } from '../../api/owners';
import MasqueradingBanner from '../Masquerading/MasqueradingBanner';
import './OverviewLayout.scss';
import Footer from 'components/Dashboard/Footer';
import { useLocation } from 'react-router';
import { RequestBanner } from 'components/RequestBanner';

const OverviewLayout = ({ children }) => {
  const { id: ownerId, isMasquerading } = useAuth();
  const { data, isLoading } = useOwnerDetails(ownerId);
  const location = useLocation();
  const showRequestsBanner = location.pathname !== '/overview/requests/' && data.hasRequests;
  return (
    <>
      {isMasquerading && !isLoading && <MasqueradingBanner identifier={data.user.email} />}
      <TopNav />
      <div className={`Overview_content${isMasquerading ? ' masquerading' : ''}`}>
        {showRequestsBanner && (
          <div className="Overview_banner">
            <RequestBanner visible={showRequestsBanner} />
          </div>
        )}
        {children}
      </div>
      <Footer />
    </>
  );
};

OverviewLayout.propTypes = {
  children: PropTypes.node,
};

export default OverviewLayout;
