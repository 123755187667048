import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import TabOverview from './TabOverview';
import TabStore from './TabStore';

const INITIAL_ACTIVE_TAB = {
  onboarding: 'overview',
};
export default function TopNavTabs({ mode, store, reset, onAddStore }) {
  const [activeTab, setActiveTab] = useState('');
  const { url = '' } = useRouteMatch();
  const initialActiveTab = useMemo(() => {
    const val = url.split('/').filter(Boolean).slice(0, 1).pop();
    return INITIAL_ACTIVE_TAB[val] ?? val;
  }, [url]);

  useEffect(() => {
    if (reset) {
      setActiveTab(initialActiveTab);
    }
  }, [reset]);

  return (
    <Tabs centered activeKey={activeTab} onChange={setActiveTab}>
      {TOP_NAV_TABS(store, reset, initialActiveTab, onAddStore, mode).map(
        ({ key, label, children, disabled = false }) => {
          return (
            <Tabs.TabPane tab={label} key={key} disabled={disabled}>
              {children}
            </Tabs.TabPane>
          );
        },
      )}
    </Tabs>
  );
}

TopNavTabs.propTypes = {
  store: PropTypes.object,
  reset: PropTypes.bool,
  onAddStore: PropTypes.func,
  mode: PropTypes.oneOf(['inline', 'horizontal']),
};

const TOP_NAV_TABS = (store, reset, initialActiveTab, onAddStore, mode) => [
  {
    label: 'Overview',
    key: 'overview',
    children: (
      <TabOverview
        mode={mode}
        reset={reset}
        onAddStore={onAddStore}
        initialActiveTab={initialActiveTab}
      />
    ),
  },
  {
    label: 'Store',
    key: 'dashboard',
    children: (
      <TabStore mode={mode} reset={reset} store={store} initialActiveTab={initialActiveTab} />
    ),
    disabled: !store?.id,
  },
];
