import React from 'react';
import PropTypes from 'prop-types';
import { Inbox } from 'react-feather';
import './EmptyData.scss';

export default function EmptyData({ label = 'No Data' }) {
  return (
    <div className="EmptyData">
      <Inbox className="EmptyData_icon" />
      <span className="EmptyData_label">{label}</span>
    </div>
  );
}

EmptyData.propTypes = {
  label: PropTypes.string,
};
