import React from 'react';
import './InputLabel.scss';

interface Props {
  label: React.ReactNode;
  required?: boolean;
}

const BASE_CLASS = 'ui-kit-input-label';

export default function InputLabel({ label, required = false }: Props) {
  return (
    <label className={BASE_CLASS}>
      {required && <span className={`${BASE_CLASS}-asterisk`}>*</span>}
      {label}
    </label>
  );
}
