import { useEffect } from 'react';

import { LEADDYNO_PUBLIC_KEY } from 'consts';
import _ from 'lodash';

declare global {
  interface Window {
    LeadDyno: any;
  }
}

export const recordLeadForLeadDyno = ({
  email,
  firstName,
}: {
  email: string;
  firstName: string;
}) => {
  if (!_.isEmpty(LEADDYNO_PUBLIC_KEY)) {
    window.LeadDyno.recordLead(email, { first_name: firstName, last_name: '' });
  }
};

const LeadDynoWidget = () => {
  useEffect(() => {
    if (!_.isEmpty(LEADDYNO_PUBLIC_KEY)) {
      window.LeadDyno.recordVisit();
      window.LeadDyno.autoWatch();
    }
  }, []);

  return null;
};

export default LeadDynoWidget;
