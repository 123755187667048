import React from 'react';
import { CountryCode } from 'api/types/store';
import { Select } from 'ui-kit';
import { getCountryByCode, countryCodes } from 'utils/geo';

interface Props {
  value: string;
  onChange: (value: CountryCode) => void;
}

const CountrySelectV2: React.FC<Props> = ({ value, onChange }) => {
  const options = countryCodes.map((code) => ({
    label: getCountryByCode(code).name,
    value: code,
  }));

  return (
    <Select
      showSearch
      filterOption={(input, option) => {
        const value = option.value.toLowerCase();
        const inputValue = input.toLowerCase();
        return value === inputValue || option.children.toLowerCase().indexOf(inputValue) >= 0;
      }}
      onChange={onChange}
      value={value}
      options={options}
    />
  );
};

export default CountrySelectV2;
