import _ from 'lodash';

export const reverse = ({
  url,
  params,
}: {
  url: string;
  params?: { [key: string]: string | number };
}) => {
  let reversedUrl = url.toString();

  if (!_.isUndefined(params)) {
    Object.keys(params).forEach((key) => {
      reversedUrl = reversedUrl.replaceAll(`:${key}`, params[key].toString());
    });
  }

  return reversedUrl;
};
