import {
  guestyApi,
  hospitableApi,
  hospitableConnectApi,
  hostawayApi,
  lodgifyApi,
  ownerRezApi,
} from 'api/partners';
import { getMassStoreStatus } from 'api/store_sdk';
import { IntegrationType } from 'api/types/partners';
import { useState, useEffect, useCallback } from 'react';

const POLLING_INTERVAL = 3000; // Poll every 3 seconds

// Polling Hook
export const usePollingImportStores = (
  massStoreRequestId: number | null,
  interval: number = POLLING_INTERVAL,
) => {
  const [isTaskReady, setIsTaskReady] = useState(false);
  const [isPolling, setIsPolling] = useState(true);
  const [createdStores, setCreatedStores] = useState([]);

  const pollTaskStatus = useCallback(async () => {
    try {
      if (massStoreRequestId === null) {
        return;
      }

      const { is_finished, request } = await getMassStoreStatus({ massStoreRequestId });

      if (is_finished) {
        setIsTaskReady(true);
        setCreatedStores(request);
        setIsPolling(false); // Stop polling when task is ready
      }
    } catch (error) {
      setIsPolling(false); // Stop polling on error
    }
  }, [massStoreRequestId]);

  useEffect(() => {
    if (isPolling) {
      const pollingInterval = setInterval(() => {
        pollTaskStatus();
      }, interval);

      return () => clearInterval(pollingInterval);
    }
  }, [pollTaskStatus, isPolling, interval]);

  return { isTaskReady, isPolling, createdStores };
};

export const getIntegrationApi = ({ company }: { company: string }) => {
  if (company === IntegrationType.LODGIFY.toLowerCase()) {
    return lodgifyApi;
  } else if (company === IntegrationType.HOSTAWAY.toLowerCase()) {
    return hostawayApi;
  } else if (company === IntegrationType.GUESTY.toLowerCase()) {
    return guestyApi;
  } else if (company === IntegrationType.HOSPITABLE.toLowerCase()) {
    return hospitableApi;
  } else if (company === IntegrationType.OWNERREZ.toLowerCase()) {
    return ownerRezApi;
  } else if (company === IntegrationType.HOSPITABLE_CONNECT.replace(' ', '').toLowerCase()) {
    return hospitableConnectApi;
  }
};
