import React from 'react';
import clsx from 'clsx';
import Svg from 'react-inlinesvg';
import { infoIcon, warningIcon } from './icons';
import './InputHelperText.scss';

type HelperTextType = 'error' | 'info';

interface Props {
  children: React.ReactNode;
  type?: HelperTextType;
}

const BASE_CLASS = 'ui-kit-input-helper-text';

export default function InputHelperText({ children, type = 'info' }: Props) {
  const renderIconByType = () => {
    if (type === 'error') {
      return <Svg src={warningIcon} />;
    }

    return <Svg src={infoIcon} />;
  };

  return (
    <span className={clsx(BASE_CLASS, `${BASE_CLASS}-${type}`)}>
      <span className={`${BASE_CLASS}-icon`}>{renderIconByType()}</span>
      <p>{children}</p>
    </span>
  );
}
