import { BASE_URL, get, post } from 'utils/sdk';

export const storeReorderProducts = ({
  storeId,
  productsPositions,
}: {
  storeId: number;
  productsPositions: Array<{
    id: number;
    position: number;
  }>;
}) =>
  post(`${BASE_URL}services/store/${storeId}/reorder-products/`, {
    positions: productsPositions,
  });

export const saveStoreOwnerProfitPercentage = ({
  storeId,
  ownerProfitPercentage,
  saveToAllStores,
}: {
  storeId: number;
  ownerProfitPercentage: number;
  saveToAllStores: boolean;
}) => {
  return post(`${BASE_URL}services/store/${storeId}/set-owner-profit-percentage/`, {
    owner_profit_percentage: ownerProfitPercentage,
    save_to_all_stores: saveToAllStores,
  });
};

// For polling if the mass store request is finished
export const getMassStoreStatus = ({ massStoreRequestId }: { massStoreRequestId: number }) =>
  // We don't need to know the actual requst data
  get<{ is_finished: boolean; request: Array<{}> }>(
    `${BASE_URL}services/store/mass-store-request/${massStoreRequestId}/`,
  );
