import React from 'react';
import './MasqueradingBanner.scss';
import PropTypes from 'prop-types';

const MasqueradingBanner = ({ identifier }) => (
  <div className="MasqueradingBanner">Masquerading as {identifier}</div>
);

MasqueradingBanner.propTypes = {
  identifier: PropTypes.string,
};

export default MasqueradingBanner;
