import React from 'react';
import clsx from 'clsx';
import './Typography.scss';

type Variant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body1'
  | 'body2'
  | 'subtitle1'
  | 'subtitle2'
  | 'caption';

type Color = 'textDark' | 'textGray' | 'textLight' | 'secondary' | 'primary';
type As = 'p' | 'span' | 'div';

interface Props extends React.HTMLAttributes<HTMLSpanElement | HTMLParagraphElement> {
  variant: Variant;
  color?: Color;
  className?: string;
  rows?: number;
  as?: As;
}

const BASE_CLASS = 'ui-kit-typography';

const Typography: React.FC<Props> = ({
  variant = 'h1',
  color = 'textDark',
  children,
  className: externalClassName,
  rows,
  as,
  ...rest
}) => {
  const getElementString = (variant: string, as: As): string => {
    if (as) {
      return as;
    }
    switch (variant) {
      case 'body1':
      case 'body2':
        return 'p';
      case 'subtitle1':
      case 'subtitle2':
        return 'h6';
      case 'caption':
        return 'span';
      default:
        return variant;
    }
  };

  const className = clsx(
    `${BASE_CLASS}`,
    `${BASE_CLASS}-${color}`,
    `${BASE_CLASS}-${variant}`,
    rows && `${BASE_CLASS}-rows-${rows}`,
    externalClassName,
  );
  return React.createElement(getElementString(variant, as), { className, ...rest }, children);
};

export default Typography;
