import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';
import useSWR from 'swr';
import useSWRInfinite, { SWRInfiniteResponse } from 'swr/infinite';

import { formatGetParams } from './utilsV2';
import { API, YOU_DO_NOT_HAVE_PERMISSION_TO_THIS_ACTION_ERROR } from 'consts';

export const BASE_URL = API.root;

export type IConfig<D> = AxiosRequestConfig<D> & {
  isFileUpload?: boolean;
  useSharableToken?: boolean;
};

export interface PaginatedResponse<T> {
  count: number;
  offset: number;
  limit: number;
  next: string | null;
  previous: string | null;
  results: Array<T>;
}

export const getConfig = <D>(params?: IConfig<D>) => {
  const { headers = {}, isFileUpload = false, ...options } = params || {};

  const contentType = isFileUpload ? 'multipart/form-data' : 'application/json';

  const config: IConfig<D> = {
    headers: {
      'Content-Type': contentType,
      // ...getAuthorizationHeader(),
      ...headers,
    } as IConfig<D>['headers'],
    withCredentials: true,
    ...options,
  };

  return config;
};

const extractData = <R, D>(response: AxiosResponse<R, D>) => response.data;

export const handleInvalidRequest = (error: any) => {
  const responseURL = error.response?.request?.responseURL;

  if (error.response?.status === 401) {
    if (responseURL !== `${BASE_URL}services/admin/masquerade/login/`) {
      // dont redirect on masquerade fail, since we're already on main page
      window.location.href = '/';
    }
    throw new Error('The API key has expired!');
  } else if (error.response?.status === 403) {
    const errorData = _.get(error, 'response.data.detail');

    if (
      !_.isUndefined(errorData) &&
      errorData[0] === YOU_DO_NOT_HAVE_PERMISSION_TO_THIS_ACTION_ERROR
    ) {
      throw new Error(errorData[0]);
    }

    if (responseURL !== `${BASE_URL}services/admin/masquerade/login/`) {
      // dont redirect on masquerade fail, since we're already on main page
      window.location.href = '/';
    }
    throw new Error('The API key has expired!');
  } else if (_.isArray(_.get(error, 'response.data'))) {
    throw new Error(_.get(error, 'response.data'));
  } else if (_.isObject(_.get(error, 'response.data'))) {
    return Promise.reject(_.get(error, 'response.data'));
  }

  throw new Error(_.get(error, 'response.data', 'Something went wrong!'));
};

export const get = <R, D = any>(url: string, params?: IConfig<D>): Promise<R> =>
  axios.get<R>(url, getConfig(params)).then(extractData).catch(handleInvalidRequest);

export const post = <R, D = any>(url: string, data?: D, params?: IConfig<D>): Promise<R> =>
  axios.post<R>(url, data, getConfig(params)).then(extractData).catch(handleInvalidRequest);

export const put = <R, D = any>(url: string, data?: D, params?: IConfig<D>): Promise<R> =>
  axios.put<R>(url, data, getConfig(params)).then(extractData).catch(handleInvalidRequest);

export const patch = <R, D = any>(url: string, data?: D, params?: IConfig<D>): Promise<R> =>
  axios.patch<R>(url, data, getConfig(params)).then(extractData).catch(handleInvalidRequest);

export const deleteCall = <R, D = any>(url: string, params?: IConfig<D>): Promise<R> =>
  axios.delete<R>(url, getConfig(params)).then(extractData).catch(handleInvalidRequest);

export const useFetch = <D>(
  url: string | undefined,
  options?: {
    revalidateOnMount?: boolean;
    revalidateIfStale: boolean;
    revalidateOnFocus: boolean;
    revalidateOnReconnect: boolean;
  },
) => {
  const result = useSWR<D>(url && BASE_URL + url, get, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    ...options,
  });

  return { ...result, isLoading: !result.error && !result.data };
};

const publicGet = <R>(url: string): Promise<R> => axios.get<R>(url).then(extractData);

export const usePublicFetch = <D>(url: string | undefined) => {
  const result = useSWR<D>(url && BASE_URL + url, publicGet, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  });

  return { ...result, loading: !result.error && !result.data };
};

export const usePaginatedFetch = <T>({
  url,
  params,
}: {
  url: string | undefined;
  params: {
    [key: string]: string | number | boolean | undefined | null;
    limit: number;
  };
}) => {
  const limit = params.limit;
  const [offset, setOffset] = useState<number>(0);
  const [pagesCount, setPagesCount] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const fetchResult = useFetch<PaginatedResponse<T>>(
    !_.isNil(url)
      ? `${url}${formatGetParams({
          offset,
          ...params,
        })}`
      : undefined,
  );

  const setPage = useCallback(
    (page: number) => {
      setCurrentPage(page);
      setOffset(limit * (page - 1));
    },
    [limit],
  );

  useEffect(() => {
    if (!_.isUndefined(fetchResult.data)) {
      setCount(fetchResult.data.count);
      setPagesCount(Math.ceil(fetchResult.data.count / fetchResult.data.limit));
    }
  }, [fetchResult.data]);

  return {
    offset,
    setOffset,
    currentPage,
    setCurrentPage,
    pagesCount,
    count,
    setPagesCount,
    setPage,
    ...fetchResult,
  };
};

export interface IInfiniteFetchResult<T>
  extends Omit<SWRInfiniteResponse<PaginatedResponse<T>>, 'data'> {
  total: number;
  data: Array<T>;
}

export const useInfiniteFetch = <T>({ url }: { url: string }): IInfiniteFetchResult<T> => {
  const fetcher = (url: string) => get(url).then((res: any) => res);

  const getKey = (pageIndex: number) => {
    return `${url}${formatGetParams({
      offset: pageIndex * 10,
      limit: 10,
    })}`;
  };

  const { data, size, setSize, ...other } = useSWRInfinite<PaginatedResponse<T>>(getKey, fetcher, {
    revalidateAll: true,
    // revalidateFirstPage: false,
  });

  return {
    data: _.flatten(data?.map((d) => d.results) || []),
    total: data?.[0]?.count || 0,
    size,
    setSize,
    ...other,
  };
};
