import dayjs from 'dayjs';

export const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export const MonthFormat = 'MMMM';

export const YearFormat = 'YYYY';

// IMPORTANT: Before this time payouts are not proccessed yet and not COMPLETED
export const payoutTimeCurrentMonth = (dt) => {
  return new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), 8, 7, 59, 0));
};

// IMPORTANT: Before this time payouts are not yet marked as PROCESSING
export const payoutStartingTimeCurrentMonth = (dt) => {
  return new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), 1, 0, 0, 0));
};

// By default dayjs interpret the input as local datetime
export const getLocalDatetime = (dt, formatter = dateTimeFormat) => {
  if (formatter) {
    return dayjs(dt).format(formatter);
  }
  return dayjs(dt).format(dateTimeFormat);
};

export const getFirstAndLastDayOfCurrentMonth = (dt) => {
  const firstDayOfMonth = new Date(dt.getFullYear(), dt.getMonth(), 1);
  const lastDayOfMonth = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
  return { firstDayOfMonth, lastDayOfMonth };
};

export const getFirstAndLastDayOfPreviousMonth = (dt) => {
  const firstDayOfMonth = new Date(dt.getFullYear(), dt.getMonth() - 1, 1);
  const lastDayOfMonth = new Date(dt.getFullYear(), dt.getMonth() - 0, 0);
  return { firstDayOfMonth, lastDayOfMonth };
};

export const getFirstAndLastDayOfMonthBeforePreviousMonth = (dt) => {
  const firstDayOfMonth = new Date(dt.getFullYear(), dt.getMonth() - 2, 1);
  const lastDayOfMonth = new Date(dt.getFullYear(), dt.getMonth() - 1, 0);
  return { firstDayOfMonth, lastDayOfMonth };
};

export const formatDateToQueryParams = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return encodeURIComponent(`${year}-${month}-${day}`);
};
