import React from 'react';

export interface IStack {
  children: React.ReactNode;
  flexDirection?: 'row' | 'column';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch';
  gap?: string;
  style?: React.CSSProperties;
  className?: string;
}

const Stack = ({
  flexDirection = 'row',
  justifyContent = 'flex-start',
  alignItems = 'flex-start',
  gap = '0',
  children,
  style,
  className,
}: IStack) => {
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexDirection,
        justifyContent,
        alignItems,
        gap,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Stack;
