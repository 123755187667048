import * as yup from 'yup';

const validSchema = yup.object({
  password: yup
    .string()
    .min(8, 'min-length')
    .matches(/[A-Z]/, 'uppercase')
    .matches(/\d/g, 'one-digit')
    .matches(/\p{P}/gu, 'special-character'),
  confirmPassword: yup
    .string()
    .test('same-password', `Password doesn't match.`, (value, { parent }) => {
      return parent.password && value === parent.password;
    })
    .required('This field is requires'),
});

export const setPasswordValidSchema = async (data) => {
  try {
    const values = await validSchema.validate(data, {
      abortEarly: false,
    });

    return {
      values,
      errors: {},
    };
  } catch (errors) {
    return {
      values: {},
      errors: errors.inner.reduce((allErrors, currentError) => {
        if (currentError.path === 'password') {
          const lastErrors = allErrors[currentError.path] ?? [];
          return {
            ...allErrors,
            [currentError.path]: [
              ...lastErrors,
              {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            ],
          };
        }
        return {
          ...allErrors,
          [currentError.path]: {
            type: currentError.type ?? 'validation',
            message: currentError.message,
          },
        };
      }, {}),
    };
  }
};
