import React from 'react';
import { Link as RouterLink, LinkProps as LinkRouterProps } from 'react-router-dom';
import clsx from 'clsx';
import './button.scss';
import Spinner from 'ui-kit/Spinner';

type Variant = 'contained' | 'outlined' | 'text';

export type Color =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'success'
  | 'textDark'
  | 'textLight'
  | 'textGray';

type Size = 'small' | 'medium' | 'large';

interface RawProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant;
  color?: Color;
  children: React.ReactNode | null;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  fullWidth?: boolean;
  size?: Size;
  loading?: boolean;
}

type LinkProps = LinkRouterProps<HTMLLinkElement>;

export type ButtonProps = RawProps &
  Omit<LinkProps, 'to'> & { to?: string | Record<string, unknown> };

const BASE_CLASS = 'ui-kit-button';

const RawButton: React.FC<RawProps> = ({ type = 'button', ...rest }) => {
  return <button type={type} {...rest} />;
};

const Link: React.FC<LinkProps> = ({ to, href, ...rest }) => {
  if (href) {
    return <a href={href} target="_blank" rel="noopener noreferrer" {...rest} />;
  }

  return <RouterLink to={to} {...rest} />;
};

const Button: React.FC<ButtonProps> = ({
  variant = 'text',
  color = 'primary',
  className,
  to,
  href,
  children,
  startIcon = null,
  endIcon = null,
  fullWidth = false,
  size = 'medium',
  loading = false,
  disabled = false,
  ...rest
}) => {
  const Component = to || href ? Link : RawButton;

  const classNameButton = clsx(
    `${BASE_CLASS}`,
    `${BASE_CLASS}-${variant}`,
    `${BASE_CLASS}-${color}`,
    `${BASE_CLASS}-${size}`,
    { [`${BASE_CLASS}-fullWidth`]: fullWidth },
    className,
  );

  const classNameIcon = `${BASE_CLASS}__icon`;
  return (
    <Component
      className={classNameButton}
      to={to}
      href={href}
      disabled={disabled || loading}
      {...rest}
    >
      {startIcon && (
        <span className={`${classNameIcon} ${classNameIcon}-start`}>
          {loading ? <Spinner size="small" /> : startIcon}
        </span>
      )}
      {endIcon && (
        <span className={`${classNameIcon} ${classNameIcon}-end`}>
          {loading ? <Spinner size="small" /> : endIcon}
        </span>
      )}
      {!startIcon && !endIcon && loading ? <Spinner size="small" /> : children}
    </Component>
  );
};

export default Button;
