/* eslint-disable react/prop-types */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { AuthorizedRoute } from '@tshio/react-router-permissions';
import { Role } from 'api/types/auth';

export const DashboardRoute = ({ children, ...rest }) => {
  return (
    <AuthorizedRoute requires={[Role.HOST]} {...rest}>
      {({ isAuthorized }) =>
        isAuthorized ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login/',
              state: {
                next: '/dashboard/',
                redirectReason: 'You have to login as a Host to be able to access this page.',
              },
            }}
          />
        )
      }
    </AuthorizedRoute>
  );
};
