import React from 'react';
import clsx from 'clsx';
import { Select as AntSelect, SelectProps } from 'antd';
import { ChevronDown as ChevronDownIcon } from 'react-feather';
import './Select.scss';

export interface Option {
  label: string;
  value: string | number;
  disabled?: boolean;
}

interface Props extends SelectProps<Option['value']> {
  options: Option[];
  className?: string;
  error?: boolean;
}

const BASE_CLASS = 'ui-kit-select';

const Select = React.forwardRef(
  ({ options = [], error = false, className, ...rest }: Props, ref: any) => {
    return (
      <AntSelect
        ref={ref}
        className={clsx(BASE_CLASS, { [`${BASE_CLASS}-error`]: error }, className)}
        suffixIcon={<ChevronDownIcon size={20} />}
        {...rest}
      >
        {options.map(({ label, value, disabled = false }, index) => {
          return (
            <AntSelect.Option key={index} value={value} disabled={disabled}>
              {label}
            </AntSelect.Option>
          );
        })}
      </AntSelect>
    );
  },
);

export default Select;
