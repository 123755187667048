import React from 'react';
import { Dropdown as AntDropdown, DropDownProps } from 'antd';
import clsx from 'clsx';
import './Dropdown.scss';

interface Props extends DropDownProps {
  children: React.ReactNode;
  className?: string;
  open?: boolean;
  onClick?: (e: React.ChangeEvent<HTMLButtonElement>) => void;
}

const BASE_CLASS = 'ui-kit-dropdown';

const Dropdown: React.FC<Props> = ({ children, className: externalClassName, ...rest }) => {
  const className = clsx(BASE_CLASS, externalClassName);
  return React.cloneElement(
    <AntDropdown className={className} {...rest} />,
    { className, ...rest },
    children,
  );
};

export default Dropdown;
