import React from 'react';
import PropTypes from 'prop-types';
import { classNames } from 'utils';
import logoSvg from './logo.svg';
import './TextLogo.scss';

export default function TextLogo({ className, logo = undefined, ...rest }) {
  if (logo === undefined) {
    return (
      <img
        className={classNames(className, 'SvgTextLogo')}
        src={logoSvg}
        width={197}
        height={23}
        {...rest}
      />
    );
  }

  return (
    <div className={classNames(className, 'TextLogo')} {...rest}>
      {logo}
    </div>
  );
}

TextLogo.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.string,
};
