import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Row, Divider } from 'antd';
import { useToken } from 'api/owners';

import 'components/LoginPage/LoginPage.scss';
import { Button, Typography } from 'ui-kit';
import UnauthLayout from 'components/UnauthLayout';
import ResetPasswordForm from './ResetPasswordForm';

export default function ResetPasswordPage() {
  const { token } = useParams<{ token: string }>();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const alertRef = useRef(null);

  useEffect(() => {
    if (error) {
      alertRef.current?.focus();
    }
  }, [error]);

  const handleSubmit = ({ password }) => {
    return useToken({ password, token }).then((res) => {
      if (res?.response?.ok === false && res?.errors?.nonFields.length) {
        setError(res?.errors?.nonFields[0]);
      }
      if (res?.response?.ok) {
        setSuccess(true);
      }
    });
  };

  return (
    <UnauthLayout>
      {error && (
        <Row justify="center" ref={alertRef} tabIndex={-1}>
          <Alert className="LoginPage_alert" type="error" message={error} />
        </Row>
      )}
      {!success ? (
        <>
          <div>
            <Typography variant="h6">Reset Password</Typography>
            <Divider />
          </div>

          <ResetPasswordForm onSubmit={handleSubmit} />
        </>
      ) : (
        <div className="LoginPage_message">
          <Row gutter={[0, 12]}>
            <Typography variant="h6">Password Changed</Typography>
            <Typography variant="body2">
              Your password has been changed! Log in to view your account.
            </Typography>
          </Row>
          <Row>
            <Button to="/login">Log in here</Button>
          </Row>
        </div>
      )}
    </UnauthLayout>
  );
}
