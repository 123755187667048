import { useEffect } from 'react';

const GetStartedPage = () => {
  useEffect(() => {
    window.location.href = 'https://www.thehost.co';
  }, []);
  return null;
};

export default GetStartedPage;
