import clsx from 'clsx';
import React from 'react';
import Button, { ButtonProps } from 'ui-kit/Button';

interface Props extends ButtonProps {
  className?: string;
  children: React.ReactNode | null;
}

const IconButton: React.FC<Props> = ({ className, children, ...rest }) => {
  return (
    <Button className={clsx('ui-kit-button-icon', className)} {...rest}>
      {children}
    </Button>
  );
};

export default IconButton;
