import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'components/ConfirmModal';
import { message } from 'antd';
import { updateStore } from 'api/store';
import { Trash as TrashIcon } from 'react-feather';
import Link from 'ui-kit/Link';
import './Settings.scss';
import { useAuth } from 'contexts/auth/auth';
import { removeOwnerrezStore } from 'utils/store';
import { Button } from 'ui-kit';

const Support = () => {
  return (
    <Link type="link" href="mailto:support@thehost.co">
      support@thehost.co
    </Link>
  );
};

const DeactivateStoreLink = ({
  storeId,
  status,
  onChange,
  buttonText,
  color,
  hasIcon,
  variant = 'text',
}) => {
  const { id: owner } = useAuth();
  const [isComfirmVisible, setConfirmVisible] = useState(false);
  const [isPosting, setPostingStatus] = useState(false);

  return (
    <>
      <ConfirmModal
        visible={isComfirmVisible}
        confirmText="Yes, archive this store"
        cancelText="No, take me back"
        confirmLoading={isPosting}
        onConfirm={() => {
          setPostingStatus(true);
          updateStore(storeId, { isActive: !status }).then(() => {
            message.success('The store has been deactivated!');
            setPostingStatus(false);
            setConfirmVisible(false);
            removeOwnerrezStore(storeId, owner);
            if (onChange) onChange(!status);
          });
        }}
        onCancel={() => {
          setConfirmVisible(false);
        }}
        destroyOnClose
      >
        <h4 className="ArchiveStore_modalTitle">Archive Store</h4>
        <p className="ArchiveStore_modalBody">
          Archiving your store will remove it from your dashboard and make it unavailable for
          customers.
        </p>
        <p className="ArchiveStore_modalBody">
          If you would like to reactivate this store in the future, contact us at&nbsp;
          <Support />.
        </p>
      </ConfirmModal>
      <Button
        className="hc-deactivate-store"
        color={color}
        variant={variant}
        onClick={() => {
          setConfirmVisible(true);
        }}
        startIcon={hasIcon && <TrashIcon />}
      >
        {buttonText}
      </Button>
    </>
  );
};

DeactivateStoreLink.propTypes = {
  storeId: PropTypes.number.isRequired,
  status: PropTypes.bool,
  onChange: PropTypes.func,
  buttonText: PropTypes.string,
  color: PropTypes.string,
  hasIcon: PropTypes.bool,
  variant: PropTypes.string,
};

DeactivateStoreLink.defaultProps = {
  buttonText: 'Archive',
};

export default DeactivateStoreLink;
