/* eslint-disable react/prop-types */
import React, { lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthorizedRoute } from '@tshio/react-router-permissions';
import { Role } from 'api/types/auth';

const OnboardingPage = lazy(() =>
  import(
    /* webpackChunkName: "store" */
    'pages/onboarding/OnboardingPage'
  ),
);

export const OnboardingRoute = ({ ...rest }) => {
  return (
    <AuthorizedRoute requires={[Role.HOST]} {...rest}>
      {({ isAuthorized }) =>
        isAuthorized ? (
          <Route path="/onboarding/" component={OnboardingPage} />
        ) : (
          <Redirect
            to={{
              pathname: '/login/',
              state: {
                next: '/dashboard/',
                redirectReason: 'You have to login as a Host to be able to access this page.',
              },
            }}
          />
        )
      }
    </AuthorizedRoute>
  );
};
