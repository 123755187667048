import React, { useState, useMemo } from 'react';
import './TopNav.scss';
import logoIconSvg from '../Dashboard/logo-icon-light.svg';
import logoTextSvg from '../Dashboard/logo-text-light.svg';
import { Menu, X } from 'react-feather';
import { useAuth } from '../../contexts/auth/auth';
import PropTypes from 'prop-types';
import { classNames } from '../../utils';
import { useOwnerDetails } from '../../api/owners';
import Tabs from './Tabs';
import { useStore } from 'contexts/store';
import { getDefaultStore } from 'utils/store';
import TabOverview from './Tabs/TabOverview';
import AccountMenu from './AccountMenu';
import { IconButton, Link } from 'ui-kit';
import NewStore from 'components/Dashboard/Store/StoreModal/Actions/NewStore';
import { useHistory } from 'react-router-dom';

const TopNav = ({ className }) => {
  const { id: ownerId, hostLogout, isMasquerading } = useAuth();
  const contextStore = useStore();
  const history = useHistory();

  const { data } = useOwnerDetails(ownerId);

  const [isNewStoreModalOpen, setIsNewStoreModalOpen] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const store = useMemo(() => {
    const defaultStore = getDefaultStore(ownerId);
    if (!defaultStore?.id && data?.stores?.length > 0) {
      return data.stores[data.stores.length - 1];
    }

    if (contextStore?.id) {
      return contextStore;
    }

    return defaultStore;
  }, [data, contextStore]);

  const handleNewStoreModalOpen = () => {
    setIsNewStoreModalOpen(true);
  };

  const handleNewStoreModalClose = () => {
    setIsNewStoreModalOpen(false);
  };

  const handleToogleDrawer = () => {
    setDrawerOpen((prev) => !prev);
  };

  const isCoHost = useMemo(() => data?.isCohost, [data]);

  return (
    <div className={classNames(className, 'TopNav', isMasquerading && 'masquerading')}>
      <Link to="/overview/stores/" className="TopNav_brand">
        <img className="TopNav_logoAsIcon" src={logoIconSvg} alt="Logo As Icon" />
        <img className="TopNav_logoAsText" src={logoTextSvg} alt="Logo As Text" />
      </Link>
      <div className="TopNav_content">
        <div className="TopNav_content-primary">
          <IconButton
            className="TopNav_content-burgerMenuButton"
            color="textLight"
            onClick={handleToogleDrawer}
          >
            <Menu className="menuIcon" />
          </IconButton>
          <TabOverview mode="horizontal" onAddStore={handleNewStoreModalOpen} />
        </div>
        <img className="TopNav_logoAsIcon" src={logoIconSvg} alt="Logo As Icon" />
        <div className="TopNav_content-secondary">
          <AccountMenu onSignOut={hostLogout} isCoHost={isCoHost} />
        </div>
      </div>
      <div className={classNames('TopNav_drawer', isDrawerOpen ? 'show' : '')}>
        <div className="TopNav_drawer_backdrop" onClick={() => setDrawerOpen((prev) => !prev)} />
        <div className="TopNav_drawer_container">
          <div className="TopNav_drawer_container-topBar">
            <IconButton
              className="TopNav_drawer_container-closeButton"
              color="textLight"
              fullWidth
              onClick={handleToogleDrawer}
            >
              <X />
            </IconButton>
          </div>
          <Tabs
            store={store}
            reset={!isDrawerOpen}
            onAddStore={handleNewStoreModalOpen}
            onLogout={hostLogout}
            mode="inline"
          />
        </div>
      </div>
      <NewStore
        isOpen={isNewStoreModalOpen}
        onCancel={handleNewStoreModalClose}
        onSuccess={(store) => history.push(`/dashboard/store/${store.id}/`)}
      />
    </div>
  );
};

TopNav.propTypes = {
  className: PropTypes.string,
};

export default TopNav;
