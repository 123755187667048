// Basic requests returning the response as is

import { API } from 'consts';

export interface RequestOptions {
  headers?: Record<string, string>;
  params?: Record<string, string>;
  payload?: Record<string, unknown>;
}

const call = async (path, options: RequestOptions = {}) => {
  const { params = {}, payload = null, ...rest } = options;
  const searchParmas = new URLSearchParams(params).toString();
  const url = new URL(`${path}`, API.root);
  url.search = searchParmas;
  return fetch(url, {
    mode: 'cors',
    credentials: 'include',
    headers: {
      ...rest?.headers,
      'Content-Type': 'application/json',
    },
    ...(payload ? { body: JSON.stringify(payload) } : {}),
    ...rest,
  });
};

export function get(path, _, options) {
  return call(path, { method: 'GET', ...options });
}

export function post(path, payload, options) {
  return call(path, { method: 'POST', payload, ...options });
}

export function patch(path, payload, options) {
  return call(path, { method: 'PATCH', payload, ...options });
}

export function put(path, payload, options) {
  return call(path, { method: 'PUT', payload, ...options });
}

export function del(path, options) {
  return call(path, { method: 'DELETE', ...options });
}
