import React from 'react';
import { Modal as AntModal, ModalProps } from 'antd';
import { X as CloseIcon } from 'react-feather';
import Typography from 'ui-kit/Typography';
import clsx from 'clsx';
import './Modal.scss';

interface Props extends ModalProps {
  children: React.ReactNode;
}

const BASE_CLASS = 'ui-kit-modal';
const Modal: React.FC<Props> = ({ children, title, className, ...props }) => {
  return React.cloneElement(
    <AntModal centered className={clsx(BASE_CLASS, className)} />,
    {
      closeIcon: <CloseIcon />,
      ...props,
    },
    <>
      {title && (
        <Typography variant="h6" className={`${BASE_CLASS}-title`}>
          {title}
        </Typography>
      )}
      {children}
    </>,
  );
};

export default Modal;
