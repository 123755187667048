/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Spin, message } from 'antd';
import { Redirect } from 'react-router-dom';
import { useAuth } from 'contexts/auth/auth';
import './LoginAnonymous.scss';

const LoginAnonymous = () => {
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const { guestLogin } = useAuth();

  useEffect(() => {
    guestLogin()
      .then(() => {
        // eslint-disable-next-line no-console
        console.log('CREATED GUEST AND LOGGED IN');
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  }, []);

  if (isError) {
    message.error('Something went wrong! Please try again later.');
    return <Redirect to="/login/" />;
  }

  return (
    <Spin spinning={isLoading}>
      <div className="LoginAnonymous">Starting a new session, please wait...</div>
    </Spin>
  );
};

export default LoginAnonymous;
