import React from 'react';
import { message } from 'antd';
import { updateStore } from 'api/store';
import { Store } from 'api/types/store';
import StoreModal, { StoreModalProps } from '../StoreModal';
import { getInitialValuesFromStore } from '../../StoreBaseForm/utils';

interface Props extends Omit<StoreModalProps, 'onSubmit'> {
  onSuccess?: (store: Store) => void;
  store: Store;
}

export default function EditStore({ onSuccess = () => null, store, ...rest }: Props) {
  const handleSubmit = (values) => {
    return updateStore(store.id, values)
      .then((res) => {
        if (res.ok) {
          onSuccess(res.data);
          return;
        }
        message.error(res.errors.nonFields?.[0]);
      })
      .catch(() => message.error('Ups there was an error, please try it later!'));
  };

  return (
    <StoreModal
      title="Edit store"
      initialValues={getInitialValuesFromStore(store)}
      onSubmit={handleSubmit}
      mode="edit"
      {...rest}
    />
  );
}
