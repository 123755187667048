import { Store } from 'api/types/store';
import * as Yup from 'yup';
import _pick from 'lodash/pick';
import { StoreBaseFormProps } from './StoreBaseForm';

export const validationStoreSchema = Yup.object().shape({
  storeName: Yup.string().required('A name is required'),
  country: Yup.string().required('Country is required'),
  postalCode: Yup.string().required('Postal code is required'),
  city: Yup.string().required('Rental city is required'),
  state: Yup.string().when('country', {
    is: 'US',
    then: Yup.string().required('State is required'),
  }),
  description: Yup.string().required('Description is required'),
});

export const getInitialValuesFromStore = (store: Store): StoreBaseFormProps['initialValues'] => {
  return _pick(store, [
    'storeName',
    'country',
    'postalCode',
    'city',
    'state',
    'description',
    'image',
    'street1',
  ]);
};
