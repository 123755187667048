export function getDefaultStore(ownerId) {
  // Default store was stored incorrectly,
  // this function is use in case there is already one saved.
  try {
    const store = JSON.parse(localStorage.getItem('defaultStore'));
    if (store?.owner !== ownerId) {
      throw new Error('This store doesnt belong to this owner');
    }
    return store;
  } catch (error) {
    return null;
  }
}

export function removeOwnerrezStore(storeId, owner) {
  const ownerrezStores = JSON.parse(localStorage.getItem(`ownerrez_added_stores_${owner}`));
  if (Array.isArray(ownerrezStores)) {
    localStorage.removeItem(`ownerrez_added_stores_${owner}`);
    localStorage.removeItem(`added_stores_${owner}`);
    return;
  }

  if (ownerrezStores?.[storeId]) {
    delete ownerrezStores[storeId];
  }

  localStorage.setItem(`ownerrez_added_stores_${owner}`, JSON.stringify(ownerrezStores));
}
