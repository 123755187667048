import React from 'react';
import { ModalProps } from 'antd';
import { Modal } from 'ui-kit';
import StoreBaseForm from '../StoreBaseForm';
import { StoreBaseFormProps } from '../StoreBaseForm/StoreBaseForm';
import './StoreModal.scss';

export interface StoreModalProps extends StoreBaseFormProps, Omit<ModalProps, 'onCancel'> {
  isOpen: boolean;
}

export default function StoreModal({
  isOpen,
  onCancel,
  onSubmit,
  initialValues,
  mode,
  ...rest
}: StoreModalProps) {
  return (
    <Modal
      className="StoreModal"
      visible={isOpen}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      {...rest}
    >
      <StoreBaseForm
        mode={mode}
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={initialValues}
      />
    </Modal>
  );
}
