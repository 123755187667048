import React from 'react';
import { Checkbox as AntCheckbox } from 'antd';
import { classNames } from 'utils';
import './checkbox.scss';

const Checkbox = ({ ...rest }) => {
  return <AntCheckbox {...rest} className={classNames('ui-kit-checkbox')} />;
};

export default Checkbox;
