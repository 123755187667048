import csc from 'country-state-city';

export const countryCodes = [
  'AD',
  'AE',
  'AF',
  'AG',
  'AI',
  'AL',
  'AM',
  'AO',
  'AQ',
  'AR',
  'AS',
  'AT',
  'AU',
  'AW',
  'AX',
  'AZ',
  'BA',
  'BB',
  'BD',
  'BE',
  'BF',
  'BG',
  'BH',
  'BI',
  'BJ',
  'BL',
  'BM',
  'BN',
  'BO',
  'BQ',
  'BR',
  'BS',
  'BT',
  'BV',
  'BW',
  'BY',
  'BZ',
  'CA',
  'CC',
  'CD',
  'CF',
  'CG',
  'CH',
  'CI',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'CR',
  'CU',
  'CV',
  'CW',
  'CX',
  'CY',
  'CZ',
  'DE',
  'DJ',
  'DK',
  'DM',
  'DO',
  'DZ',
  'EC',
  'EE',
  'EG',
  'EH',
  'ER',
  'ES',
  'ET',
  'FI',
  'FJ',
  'FK',
  'FM',
  'FO',
  'FR',
  'GA',
  'GB',
  'GD',
  'GE',
  'GF',
  'GG',
  'GH',
  'GI',
  'GL',
  'GM',
  'GN',
  'GP',
  'GQ',
  'GR',
  'GS',
  'GT',
  'GU',
  'GW',
  'GY',
  'HK',
  'HM',
  'HN',
  'HR',
  'HT',
  'HU',
  'ID',
  'IE',
  'IL',
  'IM',
  'IN',
  'IO',
  'IQ',
  'IR',
  'IS',
  'IT',
  'JE',
  'JM',
  'JO',
  'JP',
  'KE',
  'KG',
  'KH',
  'KI',
  'KM',
  'KN',
  'KP',
  'KR',
  'KW',
  'KY',
  'KZ',
  'LA',
  'LB',
  'LC',
  'LI',
  'LK',
  'LR',
  'LS',
  'LT',
  'LU',
  'LV',
  'LY',
  'MA',
  'MC',
  'MD',
  'ME',
  'MF',
  'MG',
  'MH',
  'MK',
  'ML',
  'MM',
  'MN',
  'MO',
  'MP',
  'MQ',
  'MR',
  'MS',
  'MT',
  'MU',
  'MV',
  'MW',
  'MX',
  'MY',
  'MZ',
  'NA',
  'NC',
  'NE',
  'NF',
  'NG',
  'NI',
  'NL',
  'NO',
  'NP',
  'NR',
  'NU',
  'NZ',
  'OM',
  'PA',
  'PE',
  'PF',
  'PG',
  'PH',
  'PK',
  'PL',
  'PM',
  'PN',
  'PR',
  'PS',
  'PT',
  'PW',
  'PY',
  'QA',
  'RE',
  'RO',
  'RS',
  'RU',
  'RW',
  'SA',
  'SB',
  'SC',
  'SD',
  'SE',
  'SG',
  'SH',
  'SI',
  'SJ',
  'SK',
  'SL',
  'SM',
  'SN',
  'SO',
  'SR',
  'SS',
  'ST',
  'SV',
  'SX',
  'SY',
  'SZ',
  'TC',
  'TD',
  'TF',
  'TG',
  'TH',
  'TJ',
  'TK',
  'TL',
  'TM',
  'TN',
  'TO',
  'TR',
  'TT',
  'TV',
  'TW',
  'TZ',
  'UA',
  'UG',
  'UM',
  'US',
  'UY',
  'UZ',
  'VA',
  'VC',
  'VE',
  'VG',
  'VI',
  'VN',
  'VU',
  'WF',
  'WS',
  'YE',
  'YT',
  'ZA',
  'ZM',
  'ZW',
];

export const defaultCountry = 'US';

export const stripeCrossBorderCountryCodes = [
  // Default country is US
  defaultCountry,
  // Cross-border countries by Stripe
  'AL',
  'DZ',
  'AO',
  'AG',
  'AR',
  'AM',
  'AU',
  'AT',
  'AZ',
  'BS',
  'BH',
  'BD',
  'BE',
  'BJ',
  'BT',
  'BO',
  'BA',
  'BW',
  'BN',
  'BG',
  'KH',
  'CA',
  'CL',
  'CO',
  'CR',
  'CI',
  'HR',
  'CY',
  'CZ',
  'DK',
  'DO',
  'EC',
  'EG',
  'SV',
  'EE',
  'ET',
  'FI',
  'FR',
  'GA',
  'GM',
  'DE',
  'GH',
  'GR',
  'GT',
  'GY',
  'HK',
  'HU',
  'IS',
  'IN',
  'ID',
  'IE',
  'IL',
  'IT',
  'JM',
  'JP',
  'JO',
  'KZ',
  'KE',
  'KW',
  'LA',
  'LV',
  'LI',
  'LT',
  'LU',
  'MO',
  'MG',
  'MY',
  'MT',
  'MU',
  'MX',
  'MD',
  'MC',
  'MN',
  'MA',
  'MZ',
  'NA',
  'NL',
  'NZ',
  'NE',
  'NG',
  'MK',
  'NO',
  'OM',
  'PK',
  'PA',
  'PY',
  'PE',
  'PH',
  'PL',
  'PT',
  'QA',
  'RO',
  'RW',
  'SM',
  'SA',
  'SN',
  'RS',
  'SG',
  'SK',
  'SI',
  'ZA',
  'KR',
  'ES',
  'LK',
  'LC',
  'SE',
  'CH',
  'TW',
  'TZ',
  'TH',
  'TT',
  'TN',
  'TR',
  'AE',
  'GB',
  'UY',
  'UZ',
  'VN',
];

export function getCountryByCode(code) {
  return csc.getCountryByCode(code);
}

export function getCitiesByCountryCode(code) {
  return csc.getCitiesOfCountry(code);
}

export function getCitiesByCountryAndStateCodes(countryCode, stateCode) {
  return csc.getCitiesOfState(countryCode, stateCode);
}

export function getStatesByCountryCode(code) {
  return csc.getStatesOfCountry(code);
}

export function getIsoCodeFromName(stateOrProvince, country = 'US') {
  const statesByCountry = getStatesByCountryCode(country);
  const { isoCode = null } = statesByCountry.find(({ name }) => stateOrProvince === name) ?? {};
  return isoCode;
}
