/* eslint-disable react/prop-types */
import React from 'react';
import { AuthorizedRoute } from '@tshio/react-router-permissions';
import LoginAnonymous from '../LoginAnonymous';
import { Role } from 'api/types/auth';

export const StoreRoute = ({ children, ...rest }) => {
  return (
    <AuthorizedRoute requires={[Role.GUEST_ANONYMOUS, Role.HOST]} {...rest}>
      {({ isAuthorized }) => (isAuthorized ? children : <LoginAnonymous />)}
    </AuthorizedRoute>
  );
};
